import { useState } from 'react';
import './TalentCard.scss';
import grailUtil from '../services/grailUtil';
import { Currency, Platform, Talent } from '../shared/types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import modalStyle from '../theme/mui-styles/modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface TalentCardProps {
    tal: Talent,
    showSongPrice: boolean,
    platform: Platform,
    selectedTalent: any,
    isManager?: boolean,
    isChecked?: boolean,
    add: any,
    remove: any,
    currency: Currency,
    isRec?: boolean,
    isLocked?: boolean,
}

// function getDefaultGuidePrice(priceObj: Prices | undefined, platform: Platform, showSongPrice: boolean, currency: Currency) {
//     // console.log(platform, currency);

//     priceObj = priceObj || {};

//     if (showSongPrice) {
//         return priceObj["songPrice" + platform as keyof Prices] || 0;
//     } else {

//         if (platform === "Instagram" && priceObj.brandPriceInstagramReel) {
//             return priceObj.brandPriceInstagramReel;
//         } else if (platform === "YouTube" && priceObj.brandPriceYouTubeIntegration) {
//             return priceObj.brandPriceYouTubeIntegration;
//         } else {
//             return 0;
//         }

//         // const priceKey = Object.keys(priceObj).filter((key) => key.includes(platform) && !key.includes("song"))[0];
//         // if (priceKey) {
//         //     return priceObj[priceKey as keyof Prices] || 0;
//         // } else {
//         //     return 0;
//         // }
//     }
// }

const priceAccordionStyles = {
    display: "inline-block", boxShadow: 'none', padding: 0,
    '& .MuiAccordionSummary-root': {
        padding: 0,
        fontSize: '0.75rem',
        textTransform: 'uppercase',
        color: '#E02B5F',
    },
    '& .MuiAccordionDetails-root': {
        padding: 0
    }
};

export default function TalentCard(props: TalentCardProps) {
    const { tal, showSongPrice, selectedTalent, isManager, isRec, platform, isChecked, isLocked } = props;
    const defaultPrice = tal.defaultPrice;
    const [statsModalOpen, setStatsModalOpen] = useState(false);
    const [bidPrice, setBidPrice] = useState<number | null>(selectedTalent[tal.id] || null);

    function handleCheckbox(target: HTMLInputElement, id: string) {
        const isChecked = target.checked;

        if (isChecked && (bidPrice || defaultPrice)) {
            if (bidPrice) {
                props.add(id, bidPrice, isRec);
            } else if (defaultPrice) {
                props.add(id, defaultPrice, isRec);
                setBidPrice(defaultPrice);
            }
        } else {
            props.remove(id);
            console.log(`remove`, bidPrice, defaultPrice);

            if (bidPrice === defaultPrice) {

                setBidPrice(null);
            }
        }
    }

    function handleBidPriceChange(target: HTMLInputElement) {
        const newPrice = parseInt(target.value);
        setBidPrice(newPrice);

        if (newPrice) {
            props.add(tal.id, newPrice, isRec);
        } else {
            props.remove(tal.id);
        }
    }

    return (
        <div className="TalentCard" key={tal.id}>
            <div>
                <input disabled={isLocked} checked={isChecked} type="checkbox" className="talentCheck" onChange={(e) => handleCheckbox(e.target as HTMLInputElement, tal.id)} />
            </div>
            <div className="TalentImg" style={{ backgroundImage: `url(${tal.imgUrl ? tal.imgUrl : 'talent-default.png'})` }}></div>

            <div className="core">
                {platform === "TikTok" && tal.tiktokUser ? (<div className="primary">@{tal.tiktokUser}</div>) : null}
                {platform === "Instagram" && tal.instagramUser ? (<div className="primary">@{tal.instagramUser}</div>) : null}
                {platform === "YouTube" && tal.youtubeUser ? (<div className="primary">@{tal.youtubeUser}</div>) : null}
                {/* <div className="talentName">{tal.name}</div> */}
                <div className="label">{tal.country}</div>
                {tal.managers?.[0]?.email?.includes('@grail-talent.com') ? (
                    <>
                        <div><a href={`mailto:${tal.managers[0].email}`} className="alternate-clean link-button font-12">{tal.managers[0].email}</a></div>
                    </>
                ) : null}

            </div>
            <div className="stats">
                {/* tal.ttFollowers */}
                {platform === "TikTok" && tal.tiktokUser ? (
                    <div className="statsItem">
                        <a href={`https://tiktok.com/@${tal.tiktokUser?.toLowerCase()}`} target="_blank" rel="noreferrer">
                            <img className="true-color-icon mr-50" src="/icon-tiktok.svg" alt="TikTok" />
                        </a>
                        {tal.ttFollowers ? (
                            <div>
                                <div>{grailUtil.abbreviateNumber(tal.ttFollowers)}</div>
                                <div className="label">Followers</div>
                            </div>) : null}
                    </div>
                ) : null}

                {/* tal.instagramUser ? */}
                {platform === "Instagram" && tal.instagramUser ? (
                    <div className="statsItem">
                        <a href={`https://instagram.com/${tal.instagramUser}`} target="_blank" rel="noreferrer">
                            <img className="true-color-icon mr-50" src="/icon-instagram.svg" alt="Instagram" />
                        </a>
                        {tal.igFollowers ? (
                            <div>
                                <div>{grailUtil.abbreviateNumber(tal.igFollowers)}</div>
                                <div className="label">Followers</div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {platform === "YouTube" && tal.youtubeUser ? (
                    <div className="statsItem">
                        <a href={"https://www.youtube.com/@" + tal.youtubeUser} target="_blank" rel="noreferrer">
                            <img className="true-color-icon mr-50" src="/icon-youtube.svg" alt="YouTube" />
                        </a>
                        {tal.ytFollowers ? (
                            <div>
                                <div>{grailUtil.abbreviateNumber(tal.ytFollowers)}</div>
                                <div className="label">Subscribers</div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <button className="alternate-clean link-button text-uppercase font-12" onClick={() => setStatsModalOpen(true)}>View Full Stats</button>
            </div>

            {showSongPrice ? (
                <> {isManager ? (
                    <div className="price">
                        <div className="mb-25">
                            <span className="label mr-25">Song Price:</span>
                            <strong>{grailUtil.getCurrencySymbol(props.currency)}{(defaultPrice || 0).toLocaleString()}</strong>
                        </div>
                        <div>
                            <span className="label mr-25">Offer Price:</span>
                            <input disabled={isLocked} className="customBid" value={bidPrice || ""} placeholder={(defaultPrice || 0).toLocaleString()} type="number" onChange={(e) => handleBidPriceChange(e.target)}
                                onWheel={(e) => (e.target as HTMLElement).blur()} // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
                            />
                        </div>
                    </div>
                ) : (
                    <div className="price">
                        <div className="primary mb-25">
                            {grailUtil.getCurrencySymbol(props.currency)}{(defaultPrice || 0).toLocaleString()}
                        </div>
                        <div className="label">Song Promo Price</div>
                    </div>
                )}

                </>

            ) : (
                <>
                    {platform === "TikTok" ? (
                        <div className="price">
                            <div className="mb-25">
                                <span className="label mr-25">Guide Price:</span>
                                <strong>{grailUtil.getCurrencySymbol(props.currency)}{defaultPrice?.toLocaleString()}</strong>
                            </div>
                            <div>
                                <span className="label mr-25">Offer Price:</span>
                                <input disabled={isLocked} className="customBid" value={bidPrice || ""} placeholder={(defaultPrice || 0).toLocaleString()} type="number" onChange={(e) => handleBidPriceChange(e.target)}
                                    onWheel={(e) => (e.target as HTMLElement).blur()} // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
                                />
                            </div>
                        </div>
                    ) : null}

                    {platform === "Instagram" ? (
                        <div className="price">

                            <Accordion sx={priceAccordionStyles}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="ig-prices--content"
                                    id="ig-prices-header"
                                >
                                    See Guide Prices
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                                        <span className="label mr-25">Grid Post:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceInstagramPost?.toLocaleString()}</strong>
                                        <span className="label mr-25">Reel:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceInstagramReel?.toLocaleString()}</strong>
                                        <span className="label mr-25">3-Frame Story:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceInstagramStory?.toLocaleString()}</strong>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <div className="mt-50">
                                <span className="label mr-25">Offer Price:</span>
                                <input disabled={isLocked} className="customBid" value={bidPrice || ""} placeholder={(defaultPrice || 0).toLocaleString()} type="number" onChange={(e) => handleBidPriceChange(e.target)}
                                    onWheel={(e) => (e.target as HTMLElement).blur()} // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
                                />
                            </div>
                        </div>
                    ) : null}

                    {platform === "YouTube" ? (
                        <div className="price">
                            <Accordion sx={priceAccordionStyles}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="yt-prices--content"
                                    id="yt-prices-header"
                                >
                                    See Guide Prices
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                                        <span className="label mr-25">Integration Price:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceYouTubeIntegration?.toLocaleString()}</strong>

                                        <span className="label mr-25">Preroll Price:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceYouTubePreroll?.toLocaleString()}</strong>

                                        <span className="label mr-25">Short Price:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceYouTubeShort?.toLocaleString()}</strong>

                                        {/* <span className="label mr-25">Video Price:</span>
                                        <strong>{grailUtil.getCurrencySymbol(props.currency)}{tal.localPrices?.brandPriceYouTubeVideo?.toLocaleString()}</strong> */}
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <div className="mt-50">
                                <span className="label mr-25">Offer Price:</span>
                                <input disabled={isLocked} className="customBid" value={bidPrice || ""} placeholder={(defaultPrice || 0).toLocaleString()} type="number" onChange={(e) => handleBidPriceChange(e.target)}
                                    onWheel={(e) => (e.target as HTMLElement).blur()} // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
                                />
                            </div>
                        </div>
                    ) : null}
                </>
            )
            }

            <div className="contentTags">
                {tal.category === 'Micro-influencer' ? (
                    <div className="contentTag contentTagBlack">
                        Micro-Influencer
                    </div>
                ) : null}
                {tal.contentTags?.map((tag: string, idx: number) => (
                    <div className="contentTag" key={tag + "-" + idx}>{tag}</div>
                ))}
            </div>



            <Modal open={statsModalOpen} onClose={() => setStatsModalOpen(false)}>
                <Box sx={{ ...modalStyle, padding: "0.75rem" }}>
                    {
                        tal.tiktokUser ? (
                            <div className="stats stats-row">
                                <div className="statsItem">
                                    <a href={`https://tiktok.com/@${tal.tiktokUser?.toLowerCase()}`} target="_blank" rel="noreferrer">
                                        <img className="true-color-icon mr-50" src="/icon-tiktok.svg" alt="YouTube" />
                                    </a>
                                    <div>
                                        <div>{tal.ttFollowers ? grailUtil.abbreviateNumber(tal.ttFollowers) : '-'}</div>
                                        <div className="label">Followers</div>
                                    </div>
                                </div>
                                {tal.ttStats ? (
                                    <>
                                        <div className="statsItem">
                                            <div></div>
                                            <div>
                                                <div>{tal.ttStats.averageViews ? <div>{Math.round(tal.ttStats.averageViews).toLocaleString()}</div> : '-'}</div>
                                                <div className="label">Avg. Views / Vid</div>
                                            </div>
                                        </div>

                                        <div className="statsItem">
                                            <div></div>
                                            <div>
                                                <div>{tal.ttStats.engagementRate ? <div>{Math.round(tal.ttStats.engagementRate * 1000) / 10}%</div> : '-'}</div>
                                                <div className="label">Engagement Rate</div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}


                            </div>
                        ) : null
                    }

                    {tal.instagramUser ? (
                        <div className="stats stats-row">
                            <div className="statsItem">
                                <a href={`https://instagram.com/${tal.instagramUser}`} target="_blank" rel="noreferrer">
                                    <img className="true-color-icon mr-50" src="/icon-instagram.svg" alt="YouTube" />
                                </a>
                                {tal.igFollowers ? (
                                    <div>
                                        <div>{grailUtil.abbreviateNumber(tal.igFollowers)}</div>
                                        <div className="label">Followers</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {tal.youtubeUser ? (
                        <div className="stats stats-row">
                            <div className="statsItem">
                                <a href={"https://www.youtube.com/@" + tal.youtubeUser} target="_blank" rel="noreferrer">
                                    <img className="true-color-icon mr-50" src="/icon-youtube.svg" alt="YouTube" />
                                </a>
                                {tal.ytFollowers ? (
                                    <div>
                                        <div>{grailUtil.abbreviateNumber(tal.ytFollowers)}</div>
                                        <div className="label">Subscribers</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </Box>
            </Modal>

        </div >
    );
}