import { useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { uploadFile } from "../../services/firebase";
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';

import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import ModalStyle from '../../theme/mui-styles/modal';
import { FileObject } from "../../shared/types";
import CircularProgress from "@mui/material/CircularProgress";


// function getFileFromServer(url: string) {
//     return new Promise((resolve, reject) => {
//         var xhr = new XMLHttpRequest();
//         xhr.onload = function () {
//             // callback(xhr.response);
//             resolve(xhr.response);
//         };

//         xhr.open('GET', url);
//         xhr.responseType = 'blob';
//         xhr.send();
//     });
// }

// function convertFileToDataURI(file: Blob) {
//     return new Promise((resolve, reject) => {
//         var fr = new FileReader();
//         fr.onload = () => {
//             resolve(fr.result)
//         };
//         fr.onerror = reject;
//         fr.readAsDataURL(file);
//     });
// }

interface FileUploadProps {
    existingFile?: FileObject,
    params: GridRenderEditCellParams,
}



export default function FileUpload(props: FileUploadProps) {
    const { existingFile, params } = props; //submitFileForm
    const [fileName, setFileName] = useState<string | undefined>(existingFile?.fileName);
    const [modalOpen, setModalOpen] = useState(true);

    const apiRef = useGridApiContext();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

    async function saveFile(formData: FieldValues) {
        const fileList = formData.file;

        if (fileList && fileList[0]) {
            setFileName(fileList[0]?.name);
            const fileRes = await uploadFile(fileList);
            if (fileRes?.success) {
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: { url: fileRes.result, fileName: fileList[0].name } });
                setModalOpen(false);
            }
        }
    }

    return (
        <div style={{ padding: '0 10px' }}>
            <a className="text-decoration-none" href={existingFile?.url} rel="noreferrer" target="_blank">{fileName}</a>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box sx={ModalStyle}>
                    {isSubmitting ? (
                        <>
                            <CircularProgress />
                        </>) :
                        <>
                            {fileName && existingFile ? (
                                <div style={{ "border": "1px solid #ddd", padding: '0.5rem', 'margin': '1rem', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        {/* ref={fileRef} */}
                                        <FilePresentIcon /> <a href={existingFile.url} download={existingFile.fileName}>{existingFile.fileName}</a>
                                    </div>
                                    <IconButton
                                        color="inherit"
                                        aria-label="delete file"
                                        // edge="end"
                                        onClick={() => setFileName('')}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>


                                </div>
                            ) : (
                                <form onSubmit={handleSubmit(saveFile)}>
                                    <div className={`form-group mb-100 ${errors.file ? 'error' : ''}`}>
                                        <input id="file_input" type="file" accept="image/*,.pdf,.doc,.docx" {...register("file", { required: false })} />
                                    </div>
                                    <input className="btn btn-red" type="submit" value="Save File" disabled={isSubmitting} />
                                </form>
                            )}
                        </>
                    }

                </Box>
            </Modal>
        </div>
    );
}