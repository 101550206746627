// https://github.com/NightCafeStudio/react-render-if-visible

import React, { useState, useRef, useEffect } from 'react'

const isServer = typeof window === 'undefined'

type Props = {
    /** An estimate of the element's height */
    defaultHeight?: number
    /** How far outside the viewport in pixels should elements be considered visible?  */
    visibleOffset?: number
    root?: HTMLElement | null
    children: React.ReactNode
}

const RenderIfVisible = ({
    defaultHeight = 300,
    visibleOffset = 1000,
    root = null,
    children
}: Props) => {
    const [isVisible, setIsVisible] = useState<boolean>(isServer)
    const placeholderHeight = useRef<number>(defaultHeight)
    const intersectionRef = useRef<HTMLDivElement>(null)

    // TJN NOTE: Modded this whole useEffect o prevent a no-op
    // Set visibility with intersection observer
    useEffect(() => {
        let irCurrent: HTMLDivElement | null;
        let observer: IntersectionObserver;
        let timeout: NodeJS.Timeout;
        if (intersectionRef.current) {
            timeout = setTimeout(() => {
                irCurrent = intersectionRef.current;

                observer = new IntersectionObserver(
                    entries => {
                        setIsVisible(entries[0].isIntersecting);
                    },
                    { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` }
                )
                if (irCurrent) {
                    observer.observe(irCurrent);
                }
            }, 200);
        }
        return () => {
            if (irCurrent) {
                observer.unobserve(irCurrent);
                observer.disconnect();
            }
            clearTimeout(timeout);
        }
    }, [intersectionRef, root, visibleOffset]) // TJN NOTE; added root, visibleOffset per eslint

    // Set true height for placeholder element after render.
    useEffect(() => {
        let mounted = true;

        if (mounted && intersectionRef.current && isVisible) {
            placeholderHeight.current = intersectionRef.current.offsetHeight
        }

        return () => { mounted = false };
    }, [isVisible, intersectionRef])

    return (
        <div className='RenderIfVisible' ref={intersectionRef}>
            {isVisible ? (
                <>{children}</>
            ) : (
                <div className='NonVisible' style={{ height: placeholderHeight.current }} />
            )}
        </div>
    )
}

export default RenderIfVisible