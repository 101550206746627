const ModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '1px solid #DDD',
    boxShadow: 24,
    p: 4,
    '&:focus-visible': {
        outline: 'none'
    }
};

export default ModalStyle;