import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface SectorSelectProps {
    isErrored?: boolean,
    onChange: (...event: unknown[]) => void,
    readOnly?: boolean,
    required?: boolean,
    value?: string | null,
}

export default function SectorSelect(props: SectorSelectProps) {
    const { isErrored, onChange, readOnly, required, value } = props;

    return (
        <Autocomplete
            readOnly={readOnly}
            id="sector-select"
            // sx={{ width: 300 }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    padding: '0.375rem 0.75rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    '& .MuiAutocomplete-input': {
                        padding: 'unset',
                    },
                    backgroundColor: '#fff'
                },
                '& .MuiFormControl-root': {
                    margin: 0,
                },
                '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                    border: isErrored ? '1px solid red' : '1px solid #ced4da !important'
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: isErrored ? '1px solid red' : '1px solid #ced4da !important'
                    }
                },
                '& .MuiOutlinedInput-input': {
                    border: 'none !important'
                }
            }}
            options={["Agency", "Charity", "Consumer Accessories", "Cosmetics, Make-Up & Beauty", "E-Commerce", "Fashion & Apparel", "Film & TV", "Financial Services", "Food & Beverages", "Health & Wellbeing", "Record Label", "Social Media", "Video Games & Apps", "Other"]}
            value={value}
            onChange={(e, v) => onChange(v)}
            autoHighlight
            renderInput={(params) => (
                <TextField
                    {...params}
                    // label="Choose a Sector"
                    name="Company Sector"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    required={required}
                />
            )}
        />
    );
}