import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";

const amplitudeClient = amplitude.getInstance();
amplitudeClient.init(process.env.REACT_APP_AMPLITUDE_KEY!);
if (process.env.REACT_APP_VERSION) {
    amplitudeClient.setVersionName(process.env.REACT_APP_VERSION);
}

export function analyticsService() {
    const logEvent = (eventName: string, eventProps?: object) => {
        amplitudeClient.logEvent(eventName, { ...eventProps, version: process.env.REACT_APP_VERSION });
    };

    const logScreen = (screenName: string, url: string, appInfo?: any) => {
        amplitudeClient.logEvent("Viewed Page", {
            screenName: screenName,
            url: url,
            ...appInfo,
            version: process.env.REACT_APP_VERSION,
        });
    };

    const logError = (error: Error | any) => {
        let fullError;

        try {
            fullError = JSON.stringify(error, Object.getOwnPropertyNames(error));
        } catch (e) {
            fullError = error;
        }

        amplitudeClient.logEvent("Error Occured", {
            url: window.location.pathname || '',
            domain: window.location.hostname || '',
            errorMsg: error.message || '',
            errorCause: error.cause || '',
            errorName: error.name || '',
            errorStack: error.stack || '',
            error: fullError,
            version: process.env.REACT_APP_VERSION,
        });


    }

    const setUserProperty = (propName: string, propValue: any) => {
        var property = new amplitude.Identify().set(propName, propValue);
        amplitudeClient.identify(property);

        if (process.env.NODE_ENV === 'development') {
            console.log("Development; won't set Sentry User Prop...");
        } else {
            Sentry.setUser({ [propName]: propValue });
            console.log("SETTING SENTRY USER PROP", propName, propValue);
        }
    };

    const setUserId = (id: string) => {
        amplitudeClient.setUserId(id);

        if (process.env.NODE_ENV === 'development') {
            console.log("Development; won't set Sentry User Id...");
        } else {
            Sentry.setUser({ id: id });
            console.log("SETTING SENTRY USER ID", id);
        }
    };

    const logOut = () => {
        amplitudeClient.setUserId(null); // not string 'null'
        amplitudeClient.regenerateDeviceId();
    }

    return { logEvent, logScreen, setUserProperty, setUserId, logOut, logError };
}