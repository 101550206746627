export default function ChipOnClick(label: string, onClick: React.MouseEventHandler | any | string) {
    return (
        <>
            <div>
                {label ?
                    typeof onClick === 'string' ? (
                        <a className="btn btn-tag" href={onClick} target="_blank" rel="noreferrer">{label}</a>
                    ) : (
                        <button className="btn btn-tag" onClick={onClick}>{label}</button>
                    ) : null}
            </div>
        </>
    )
}