import { useState } from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid-premium';

import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';

import ModalStyle from '../../theme/mui-styles/modal';

import DataLoading from "../DataLoading";

export default function CustomToolbar(props: any) {
    const { limitToManager, setLimitToManager, limitToLast3Months, setLimitToLast3Months, isManager } = props;
    const [openModalId, setOpenModalId] = useState<string>('');

    return (
        <div className="px-100 flex flex-wrap align-items-center justify-content-between">
            {/* <GridToolbar showQuickFilter /> */}

            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {/* <GridToolbarQuickFilter /> */}
                {/* <GridToolbarDensitySelector /> */}
                {/* <GridToolbarExport /> */}
                <Link className="hidden-sm-up" to="/new-booking">
                    <Button sx={{ 'border': '1px solid #62edff', 'height': '2rem', 'borderRadius': '1rem', 'color': 'black !important' }} variant="outlined" color="inherit">+ Booking</Button>
                </Link>
            </GridToolbarContainer>

            {isManager ?
                <>
                    <div className="flex align-items-center justify-content-center">
                        {/* talentQuery.isFetching ||  */}
                        <DataLoading />
                        <div className="ml-50">
                            <IconButton size="small" onClick={() => setOpenModalId('settings')}>
                                <SettingsIcon />
                            </IconButton>
                        </div>
                        {/* <div className="ml-50 NewBookingButton hidden-sm-down">
                                    <Link className="btn btn-primary" to="/new-booking">
                                        New Booking
                                    </Link>
                                </div> */}
                    </div>

                    <Modal open={openModalId === 'settings'} onClose={() => setOpenModalId('')}>
                        <Box sx={ModalStyle}>
                            <FormControlLabel sx={{ fontSize: '0.5rem' }} control={<Switch checked={limitToManager} onChange={(e, checked) => setLimitToManager(checked)} />} label={<div className='flex align-items-center'>Only Show My Bookings <strong className="ml-50 font-10">(Much Faster)</strong></div>} />
                            <FormControlLabel sx={{ fontSize: '0.5rem' }} control={<Switch checked={limitToLast3Months} onChange={(e, checked) => setLimitToLast3Months(checked)} />} label={<div className='flex align-items-center'>Only Show Last 3 Months <strong className="ml-50 font-10">(Faster)</strong></div>} />
                        </Box>
                    </Modal>
                </> : null}
        </div>
    );
}