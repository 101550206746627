import { useState } from "react";

import Popper from "@mui/material/Popper";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { useGridApiContext } from "@mui/x-data-grid-pro";

const EditTextarea = (props: GridRenderEditCellParams) => {
    const { id, field, value, colDef } = props; //api
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
    const apiRef = useGridApiContext();

    const handleRef = (el: HTMLDivElement) => {
        setAnchorEl(el);
    };

    const handleChange = (event: any) => { //event: React.SyntheticEvent, newValue: number | null
        console.log(event);
        apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    };

    return (
        <div>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    // width: colDef.computedWidth,
                    display: "block",
                    // position: "relative",
                    // bottom: -32
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement="top-start">
                    <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
                        <InputBase
                            multiline
                            rows={4}
                            value={value || ''}
                            onChange={handleChange}
                        />
                    </Paper>
                </Popper>
            )}
        </div>
    );
};

export default EditTextarea;