import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../shared/types';

// Components
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';

// ICONS
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import CampaignIcon from '@mui/icons-material/Campaign';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListIcon from '@mui/icons-material/List';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

interface PrimaryNavigationProps {
    signOut: Function,
    user?: User,
}

export default function PrimaryNavigation(props: PrimaryNavigationProps) {
    const { signOut, user } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const listIconSx = {
        minWidth: 'unset',
        marginRight: '1rem',
    };

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={() => setDrawerOpen(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                sx={{
                    //   width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        // width: drawerWidth,
                    },
                }}
                // variant="persistent"
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <div style={{ 'width': 'calc(100vw - 48px)', 'maxWidth': '340px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
                    <List>
                        {user?.roles?.manager || user?.roles?.admin || user?.roles?.booker || user?.roles?.brandTeam || user?.roles?.salesTeam ? (
                            <ListItem onClick={() => navigate('/new-booking')} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon sx={listIconSx}><AddBoxIcon /></ListItemIcon>
                                    <ListItemText primary="New Booking" />
                                </ListItemButton>
                            </ListItem>
                        ) : null}

                        {user?.roles?.manager ? (
                            <>
                                <ListItem onClick={() => navigate(`/roster/${user?.id}`)} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon sx={listIconSx}><GroupIcon /></ListItemIcon>
                                        <ListItemText primary="My Roster" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem onClick={() => navigate(`/roster/`)} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon sx={listIconSx}><GroupsIcon /></ListItemIcon>
                                        <ListItemText primary="Grail Roster" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem onClick={() => navigate(`/lists/${user.id}`)} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon sx={listIconSx}><ListIcon /></ListItemIcon>
                                        <ListItemText primary="My Lists" />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        ) : null}

                        {user?.roles?.booker ? (
                            <>
                                <ListItem onClick={() => navigate(`/campaigns/`)} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon sx={listIconSx}><CampaignIcon /></ListItemIcon>
                                        <ListItemText primary="My Campaigns" />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        ) : null}

                        {
                            user?.roles?.admin ?
                                <>
                                    <Divider sx={{ margin: '1rem 0' }} />
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        ADMIN TOOLS
                                    </ListSubheader>
                                    <ListItem onClick={() => navigate('/managers')} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon sx={listIconSx}><AdminPanelSettingsIcon /></ListItemIcon>
                                            <ListItemText primary="Managers List" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem onClick={() => navigate('/test')} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon sx={listIconSx}><AdminPanelSettingsIcon /></ListItemIcon>
                                            <ListItemText primary="Test" />
                                        </ListItemButton>
                                    </ListItem>
                                </> : null
                        }

                        {/* {user?.email === 'timothy.noetzel@gmail.com' ?
                            <>
                                <ListItem onClick={() => { throw new Error('FOOBAR!') }} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon sx={listIconSx}><GroupIcon /></ListItemIcon>
                                        <ListItemText primary="Trigger Sentry Error" />
                                    </ListItemButton>
                                </ListItem>
                            </> : null
                        } */}
                    </List>

                    <List>
                        <ListItem onClick={() => window.open('https://grail-talent.com/terms', '_blank')} disablePadding>
                            <ListItemButton>
                                <ListItemIcon sx={listIconSx}><InsertDriveFileOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Terms" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => signOut()} disablePadding>
                            <ListItemButton>
                                <ListItemIcon sx={listIconSx}><LogoutIcon /></ListItemIcon>
                                <ListItemText primary="Log Out" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>


            </Drawer>
        </>);
}