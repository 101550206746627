import { useState, useEffect, useContext } from 'react';
import { updateDocField, updateDoc, UserContext, useFreshTalent, useBookings, useFreshClients, useFreshCampaigns, useFreshBookers, useFreshManagers } from '../services/firebase';
import { Campaign, Client, bookingStatusOptions } from '../shared/types'; // User, Talent
import grailUtil from '../services/grailUtil';

// const accounting = require("accounting");
import accounting from 'accounting';
import dayjs from 'dayjs';

// Components
import Layout from '../components/Layout';
import BookingActionButton from '../components/grid/BookingActionButton';
import Box from '@mui/material/Box';
import { DataGridPremium, GridColDef, useGridApiRef, GridPreProcessEditCellProps, GridEditInputCell, GridRenderEditCellParams, GridFilterInputValueProps, GridLogicOperator, GridFilterOperator, GridAggregationFunction, getGridSingleSelectOperators, FilterColumnsArgs } from '@mui/x-data-grid-premium'; //GridCellEditCommitParams, MuiEvent, // GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton,
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import Chip from '@mui/material/Chip';

//
import CustomToolbar from '../components/grid/CustomToolbar';
import StatusCell from '../components/grid/StatusCell';
import CurrencyCell from '../components/grid/CurrencyCell';
import ChipOnClickCell from '../components/grid/ChipOnClickCell';

// Styles
import './Bookings.scss';
import FileUpload from '../components/grid/FileUpload';
import TextAreaCell from '../components/grid/TextAreaCell';


const currencyFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
});

const monthOptions = grailUtil.createMonthOptions();

function Bookings() {
    const user = useContext(UserContext);
    const apiRef = useGridApiRef();

    const [limitToManager, setLimitToManager] = useState(true);
    const [limitToLast3Months, setLimitToLast3Months] = useState(true);

    // useBookings
    const { bookings } = useBookings(user?.id || '', apiRef, limitToManager, limitToLast3Months);

    /// REACT QUERY
    const bookersQuery = useFreshBookers();
    const bookers = bookersQuery.flatData;
    const managersQuery = useFreshManagers();
    const managers = managersQuery.flatData;
    const salesTeam = (managers || [])?.filter((manager) => manager.roles?.salesTeam);
    const clientsQuery = useFreshClients();
    const clients = clientsQuery.flatData;
    const campaignsQuery = useFreshCampaigns(limitToLast3Months);
    const campaigns = campaignsQuery.flatData;
    const talentQuery = useFreshTalent(true, true);
    const talent = talentQuery.flatData;

    useEffect(() => {
        document?.body?.classList.add('overflow-x-hidden');
    }, []);

    function validateVideo(params: GridPreProcessEditCellProps) {
        let error = false;
        let errormessage;

        // const regex = /^(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/video\/[A-Za-z0-9-']*[0-9])/;
        const regex = /^(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/video\/[A-Za-z0-9-']*[0-9])|(https:\/\/www\.instagram\.com\/reel\/[A-Za-z0-9-']*)|(https:\/\/www\.instagram\.com\/p\/[A-Za-z0-9-']*)|(https:\/\/www\.instagram\.com\/stories\/[A-Za-z0-9-_.']*\/[A-Za-z0-9-']*)|(https:\/\/www\.youtube\.com\/shorts\/[A-Za-z0-9-_.']*)|(https:\/\/twitter\.com\/[A-Za-z0-9-_']*\/status\/[A-Za-z0-9-']*)|(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/photo\/[A-Za-z0-9-']*[0-9])|(https:\/\/www\.snapchat\.com\/spotlight\/[A-Za-z0-9-_']*)|(https:\/\/www\.youtube\.com\/[A-Za-z0-9-_'?=]*)/;

        if (params.props.value && !params.props.value.match(regex)) {
            error = true;
            errormessage = (
                <div>
                    <div>Use any of the following formats:
                        <ul>
                            <li>https://www.tiktok.com/@user/video/1234567</li>
                            <li>https://www.tiktok.com/@user/photo/1234567</li>
                            <li>https://www.instagram.com/reel/CfpM3FtDoI0</li>
                            <li>https://www.instagram.com/p/CiiBfmdMQtp</li>
                            <li>https://www.instagram.com/stories/jackbeanuk/2935854530030546322</li>
                            <li>https://www.youtube.com/shorts/0T5yt0MzmdQ</li>
                            <li>https://twitter.com/whitest_injera/status/1621697248378687490</li>
                            <li>https://www.snapchat.com/spotlight/ABC123</li>
                        </ul>

                    </div>
                </div>
            );
        }
        return { ...params.props, error: error, errormessage: errormessage };
    }

    function validateEmail(params: GridPreProcessEditCellProps) {
        let error = false;
        let errormessage;

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (params.props.value && !params.props.value.match(regex)) {
            error = true;
            errormessage = (
                <div>
                    <div>Enter a valid email address.</div>
                </div>
            );
        }
        return { ...params.props, error: error, errormessage: errormessage };
    }

    function validateNumber(params: GridPreProcessEditCellProps) {
        let error = false;
        let errormessage;

        let newVal = cleanNumber(params.props.value); // Cast as a number
        if (params.props.value && Number.isNaN(newVal)) {
            error = true;
            errormessage = (
                <div>
                    <div>Must be a number with no currency.</div>
                </div>
            );
        } else if (!params.props.value) {
            // Do nothing if it's empty
        } else {
            params.props.value = newVal; // set the number, so that on save it's saved as a number...
        }

        return { ...params.props, error: error, errormessage: errormessage };

        function cleanNumber(stringNum: string | number) {
            if (typeof stringNum === 'string') {
                stringNum = stringNum.replaceAll(',', '');
            }
            return Number(stringNum);
        }
    }

    function cellWithValidation(props: GridRenderEditCellParams) {
        return (
            <div>
                <div>
                    {
                        props.colDef.field === 'price' ?
                            <GridEditInputCell {...{ ...props, value: props.value ? accounting.unformat(props.value) : null }} />
                            : <GridEditInputCell {...props} />
                    }
                </div>
                <Box sx={{ width: '100%' }}>
                    <Tooltip componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#E02B5F',
                                '& .MuiTooltip-arrow': {
                                    color: '#E02B5F',
                                },
                            },
                        },
                    }} open={!!props.error} title={props.errormessage || ''} arrow>
                        <div></div>
                    </Tooltip>
                </Box>
            </div>
        );
    }

    interface selectOption {
        value: string;
        label: string;
    }

    function sortSelectOptions(a: selectOption | string, b: selectOption | string) {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        } else if (typeof a !== 'string' && typeof b !== 'string') {
            return a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1;
        } else {
            // never happens
            return 1;
        }
    }
    // options: selectOption[]
    // TJN TO-DO MAYBE: eliminate any[]
    function TypeaheadSingleSelect(props: GridFilterInputValueProps & { optionsArr: any[], labelParam: string }) {
        const { item, applyValue, optionsArr, labelParam } = props; // focusElementRef
        const options = optionsArr.map((opt) => ({ value: opt.id || '', label: (opt[labelParam] || '').toLowerCase() as string })).sort(sortSelectOptions);

        function createLabel() {
            return optionsArr.find((opt) => opt.id === item.value)?.[labelParam] || '';
        }

        return (
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={item.value ? { value: item.value || '', label: createLabel() } : null}
                // value={{ value: item.value, label: managers.find((manager) => manager.id === item.value)?.email || '' }}
                onChange={(event, value) => { applyValue({ ...item, value: value?.value }) }}
                id="combo-box-demo"
                options={options} // options={options.sort(sortSelectOptions)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Value" variant="standard" />}
            />
        );
    }

    function TypeaheadMultiSelect(props: GridFilterInputValueProps & { optionsArr: any[], labelParam: string | null }) { // multiple?: boolean
        const { item, applyValue, optionsArr, labelParam } = props; // focusElementRef, multiple

        // console.log(`item`, item);

        console.log('labelParam', labelParam);

        let options;

        if (labelParam) {
            options = (optionsArr).map((opt) => ({ value: opt.id || '', label: (opt[labelParam] || '').toLowerCase() as string }));
        } else {
            options = optionsArr;
        }

        console.log(`value from options`, options.find((opt) => opt.value === 'd3X2hL81cdSG9lsOYP5q'));

        return (
            <Autocomplete
                multiple
                // isOptionEqualToValue={(option, value) => value.value.find((val) => val.value === option.value)}
                value={item.value || []}
                onChange={(event, value) => { console.log(`value fron change`, value); applyValue({ ...item, value: value }) }}
                id="combo-box-demo"
                options={options.sort(sortSelectOptions)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Value" variant="standard" />}
            />
        );
    }

    // selectOption[]
    function createSingleSelectFilterOperators(optionsArr: any[], labelParam: string) {
        return [
            {
                'label': 'is',
                'value': 'is',
                // https://mui.com/x/react-data-grid/filtering/#create-a-custom-operator
                getApplyFilterFn(filterItem, column) {
                    // console.log(`filterItem`, filterItem);

                    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                        return null;
                    }
                    return (params): boolean => {
                        return params?.value?.includes(filterItem.value)
                    };
                },
                // value={{ value: item.value, label: managers.find((manager) => manager.id === item.value)?.email || '' }}
                InputComponent: (props: GridFilterInputValueProps) => TypeaheadSingleSelect({ ...props, optionsArr, labelParam }),
            },
            {
                'label': 'is not',
                'value': 'isNot',
                getApplyFilterFn(filterItem, column) {
                    // console.log(`filterItem`, filterItem);

                    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                        return null;
                    }
                    return (params): boolean => {
                        return !params?.value?.includes(filterItem.value)
                    };
                },
                InputComponent: (props: GridFilterInputValueProps) => TypeaheadSingleSelect({ ...props, optionsArr: optionsArr, labelParam })
            },
            {
                'label': 'is any of',
                'value': 'isAnyOf',
                getApplyFilterFn(filterItem, column) {
                    if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                        return null;
                    }
                    return (params): boolean => {
                        if (typeof params.value === 'object') {
                            return params?.value?.some((i: any) => filterItem.value.find((val: selectOption) => val.value === i));
                        } else {
                            return filterItem.value.find((val: any) => val.value === params.value);
                        }
                    };
                },
                InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam }) // labelParam: 'email'
            },
            {
                'label': 'is not any of',
                'value': 'isNotAnyOf',
                getApplyFilterFn(filterItem, column) {
                    if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                        return null;
                    }
                    return (params): boolean => {
                        if (typeof params.value === 'object') {
                            return !params?.value?.some((i: any) => filterItem.value.find((val: selectOption) => val.value === i));
                        } else {
                            // return !filterItem.value.includes(params.value);
                            return !filterItem.value.find((val: any) => val.value === params.value);
                        }
                    }
                },
                InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam }) // labelParam: 'email'
            }
        ] as GridFilterOperator[]; // <any, any, any>[]
    }

    // {
    //     'label': 'is not any of',
    //     'value': 'isNotAnyOf',
    //     getApplyFilterFn(filterItem, column) {
    //         if (!filterItem.columnField || !filterItem.value?.length || !filterItem.operatorValue) {
    //             return null;
    //         }
    //         return (params): boolean => {
    //             return !params?.value?.some((i: any) => filterItem.value.find((val: selectOption) => val.value === i));
    //         }
    //     },
    //     InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam: 'email' })
    // }

    function createIsNotAnyFilterOperator(optionsArr: any[]) {
        return {
            'label': 'is not any of',
            'value': 'isNotAnyOf',
            getApplyFilterFn(filterItem, column) {
                if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return !filterItem.value.includes(params.value);
                }
            },
            InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam: null })
        } as GridFilterOperator;
    }

    // function createIsNotFilterOperator() {
    //     // const existingFilters = getGridStringOperators();

    //     // return [
    //     //     ...existingFilters,
    //     return {
    //         'label': 'is not',
    //         'value': 'isNot',
    //         getApplyFilterFn(filterItem, column) {
    //             if (!filterItem.columnField || !filterItem.value?.length || !filterItem.operatorValue) {
    //                 return null;
    //             }
    //             return (params): boolean => {
    //                 return params.value !== filterItem.value;
    //             }
    //         },
    //         // onChange={(event, value) => { applyValue({ ...item, value: value }) }}
    //         // InputComponent: (props: GridFilterInputValueProps) => <input value={props.item.value} type="text" onChange={(e) => props.applyValue({ ...props.item, value: e.target.value })} />
    //         InputComponent: (props: GridFilterInputValueProps) => (
    //             <FormControl variant="standard">
    //                 <InputLabel id={'IsNotFilterLabel'}>Value</InputLabel>
    //                 <Select
    //                     labelId={'IsNotFilterLabel'}
    //                     value={props.item.value || ''}
    //                     label="Age"
    //                     onChange={(e) => props.applyValue({ ...props.item, value: e.target.value })}
    //                 >
    //                     {bookingStatusOptions.map((statusOpt) =>
    //                         <MenuItem key={statusOpt} value={statusOpt}>{statusOpt}</MenuItem>
    //                     )}
    //                 </Select>
    //             </FormControl>
    //         )
    //     } as GridFilterOperator;
    // }


    function getUniqueObjectsByKey(data: any, key: string) {
        // data = data.filter((dat: any) => dat[key]);

        if (data) {
            return Object.values(
                data.reduce((acc: any, cv: any) => {
                    // if (!acc[cv.name.toLowerCase()]) acc[cv.name.toLowerCase()] = cv;
                    // return acc;

                    const k = cv[key]?.toLowerCase().trim();
                    if (!acc[k]) {
                        acc[k] = cv
                    }
                    return acc;
                }, {})
            );
        } else {
            return [];
        }
    }

    function agentCommissionValueGetter(params: any) {
        if (typeof params.row.overrideManagerCommission === 'number') {
            return params.row.overrideManagerCommission;
        } else if (params.row.overrideRemoveCommissions || params.row.overrideMicroRevenue) {
            return 0;
        } else {
            if (params.row.approvalDate && dayjs(grailUtil.timestampOrDateToDate(params.row.approvalDate)).isBefore(dayjs('2023-10-01'))) {
                return (params.row.price - (params.row.price * 0.015)) * 0.1;
            }
            return (params.row.price - (params.row.price * 0.02375)) * 0.1;
        }
    }

    // NOTE - This is the most memory intensive of the aggregation function subcomponents, if we need to revisit...
    function externalCommissionValueGetter(params: any) {
        return params.row.talentId ? talent?.find((tal) => tal.id === params.row.talentId)?.externalCommission : 0;
        // return 0;
    }

    ////////////////
    // Columns
    //////////////// 
    const columnId: GridColDef = { field: 'id', headerName: 'Grail Platform ID', width: 250, aggregable: false };
    const columnAirtableId: GridColDef = { field: 'airtableId', headerName: 'Airtable ID', width: 250, aggregable: false };
    const columnTalentName: GridColDef = {
        field: 'talentName', headerName: 'Talent', width: 150, aggregable: false,
        valueFormatter: (params) => params.value ? '@' + params.value : null, renderCell: (params) => ChipOnClickCell(params.value, user?.roles?.manager ? `/edit-talent/${params.row.talentId}` : null),
        valueGetter: (params) => {
            const curTal = talent?.find((tal) => tal.id === params.row.talentId);
            return curTal?.tiktokUser || curTal?.instagramUser || curTal?.youtubeUser || null
        }
    };
    const columnManagerIds: GridColDef = {
        field: 'managerIds',
        headerName: 'Manager',
        aggregable: false,
        filterOperators: createSingleSelectFilterOperators(managers || [], 'email'),
        renderCell: (params) => {
            return <>{(params.value as string[])?.map((managerId) => <div key={managerId}>
                <Link className="btn btn-tag" to={`/roster/${managerId}`} target="_blank" rel="noreferrer">{managers?.find((manager) => manager.id === managerId)?.email?.toLowerCase()}</Link>
            </div>)}</>
        }
    };
    const columnClient: GridColDef = {
        field: 'clientId',
        type: 'singleSelect',
        headerName: 'Client',
        width: 150,
        aggregable: false,
        filterOperators: createSingleSelectFilterOperators(getUniqueObjectsByKey(clients, "name"), 'name'), // TJN TO-DO: not lowerCase...
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.row.bookerId); // check the clientId on the booker 1st, so it's up to date
            // check the clientId on the booking 2nd, just in case...
            const client: Client | undefined = clients?.find((client) => client.id === booker?.clientId || client.id === params.value);

            if (client) {
                return ChipOnClickCell(client.name || client.id || 'Client', `/edit-client/${client.id}`);
            } else if (params.row.clientId) {
                return ChipOnClickCell(params.row.clientName || params.row.clientId, `/edit-client/${params.row.clientId}`);
            } else if (params.row.clientName) {
                return params.row.clientName;
            } else {
                return (<></>);
            }
        },
        valueOptions: clients?.map((client) => ({ value: client.id!, label: client.name || client.id! })).sort(sortSelectOptions),
    };
    const columnBooker: GridColDef = {
        field: 'bookerId',
        type: 'singleSelect',
        headerName: 'Booker',
        width: 150,
        aggregable: false,
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.value);
            if (booker) {
                return ChipOnClickCell(booker.email || '', `/edit-user/${booker.id}`);
            } else {
                return (<></>);
            }
        },
        filterOperators: createSingleSelectFilterOperators(getUniqueObjectsByKey(bookers, "email"), 'email'),
    };
    const columnCampaign: GridColDef = {
        field: 'campaign',
        headerName: 'Campaign (Type)',
        width: 150, aggregable: false,
        valueGetter: (params) => (params.row.campaignId || params.row.airtableType) ? campaigns.find((campaign) => campaign.id === params.row.campaignId)?.campaignName || params.row.airtableType || params.row.campaignId : null,
        renderCell: (params) => {
            const campaignId = params.row.campaignId;
            const campaign = campaignId ? campaigns.find((campaign) => campaign.id === campaignId) : null;
            const type = params.row.airtableType ? params.row.airtableType + " (Type)" : null;
            if (campaign) {
                return ChipOnClickCell(campaign.campaignName || '', `/campaign/${campaign.id}`);
            } else if (campaignId) {
                return ChipOnClickCell(type || campaignId, `/campaign/${campaignId}`);
            } else {
                return (
                    <>
                        {type || null}
                    </>
                );
            }
        }
    };
    const columnDocMeta: GridColDef = {
        field: 'docMeta', type: 'date', headerName: 'Date Created', width: 125,
        aggregable: false,
        valueGetter: (params) => params.value ? new Date(params.value?.createTime?.toDate()) : null
        // If restoring from JSON...
        // valueGetter: (params) => params.value?.createTime ? new firebase.firestore.Timestamp(params.value.createTime.seconds, params.value.createTime.nanoseconds)?.toDate() : null
    };
    const columnCreatedByManager: GridColDef = {
        field: 'createdByManager', headerName: "Created by Grail Team", type: 'boolean', aggregable: false, width: 150,
        valueGetter: (params) => params.value || params.row.lastUpdateByManager,
    };
    const columnMonthDue2: GridColDef = {
        field: 'monthDue2', headerName: 'Month Due', type: 'singleSelect', editable: true, width: 125, aggregable: false,
        valueOptions: monthOptions,
        valueGetter: (params) => params.row.id ? grailUtil.timestampToMonthDue(params.value) : null,
        valueSetter: (params) => { return { ...params.row, monthDue2: grailUtil.monthDueToTimestamp(params.value) } },
        groupingValueGetter: (params) => grailUtil.timestampToMonthDue(params.value),
        sortComparator: grailUtil.sortMonthOptions,
    };
    const columnCurrency: GridColDef = { field: 'currency', headerName: 'Currency', width: 100, renderCell: CurrencyCell };
    const columnPrice: GridColDef = {
        field: 'price', type: 'number', headerName: 'Booking Price', width: 150, align: 'right', editable: true,
        preProcessEditCellProps: validateNumber,
        renderEditCell: cellWithValidation,
        valueFormatter: (params) => params.value ? accounting.formatMoney(params.value, grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency), 2, ",", ".") : null,
    };
    const columnOverrideMicroRevenue: GridColDef = {
        field: 'overrideMicroRevenue', headerName: "Creator Commmission Override", type: 'number', aggregable: false, width: 150, editable: true,
        valueFormatter: ({ value, id }) => typeof value === 'number' ? grailUtil.getCurrencySymbol(apiRef.current.getRow(id || '').currency) + currencyFormat.format(value) : ""
    };
    const columnOverrideManagerCommission: GridColDef = {
        field: 'overrideManagerCommission', headerName: "Manager Commmission Override", type: 'number', aggregable: false, width: 150, editable: true,
        valueFormatter: (params: { value: any, id?: string | number }) => typeof params.value === 'number' ? grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency) + currencyFormat.format(params.value) : "",
    };
    const columnExternalCommission: GridColDef = {
        field: "externalCommission", headerName: "External Commission", editable: false,
        valueGetter: externalCommissionValueGetter,
        valueFormatter: (params: { value: any, id?: string | number }) => typeof params.value === 'number' ? grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency) + currencyFormat.format(params.value) : "",
    };
    const columnOverrideRemoveCommissions: GridColDef = { field: "overrideRemoveCommissions", headerName: "Expenses: Remove Commission?", type: 'boolean', editable: true };
    const columnAgentCommission: GridColDef = {
        field: 'agentCommission', type: 'number', headerName: 'Commission', width: 150, align: 'right',
        valueGetter: agentCommissionValueGetter,
        valueFormatter: (params) => params.value || typeof params.value === 'number' ? accounting.formatMoney(params.value, grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency), 2, ",", ".") : null,
        sortComparator: (a, b) => accounting.unformat(a) - accounting.unformat(b),
    };
    const columnStatus: GridColDef = {
        field: 'status', headerName: 'Status', width: 150, aggregable: false, type: 'singleSelect',
        renderCell: StatusCell,
        valueOptions: bookingStatusOptions.map((opt) => opt), filterOperators: [...getGridSingleSelectOperators(), createIsNotAnyFilterOperator(bookingStatusOptions.map((opt) => opt))]
    };
    const columnVideoLink: GridColDef = { field: 'videoLink', headerName: 'Video Link', width: 150, aggregable: false, editable: true, preProcessEditCellProps: validateVideo, renderEditCell: cellWithValidation, };
    const columnBoostCode: GridColDef = { field: 'boostCode', headerName: 'Boost Code', width: 150, aggregable: false, editable: true, type: 'string' };
    const columnBookingType: GridColDef = {
        field: 'bookingType', headerName: 'Special Booking Type', type: 'singleSelect', width: 150, aggregable: false, editable: true,
        valueOptions: ["downpayment", "event", "cancelled campaign production fee", "expenses"],
        renderCell: (params) => params.value ? <Chip label={params.value.charAt(0).toUpperCase() + params.value.slice(1)} variant="outlined" color="success" size="small" /> : null,
    };
    const columnPurchaseOrder: GridColDef = { field: 'purchaseOrder', headerName: 'PO Number', width: 150, aggregable: false, editable: true };
    const columnInvoiceNotes: GridColDef = { field: 'invoiceNotes', type: 'string', headerName: 'Invoice Notes', width: 150, aggregable: false, editable: true, renderEditCell: (params) => { return <TextAreaCell {...params} /> } };
    const columnUniportContactEmail: GridColDef = { field: 'uniportEmail', headerName: 'Uniport Contact Email', aggregable: false, width: 150, editable: true, preProcessEditCellProps: validateEmail, renderEditCell: cellWithValidation };
    const columnSalesLeadId: GridColDef = {
        field: 'salesLeadId', headerName: 'Sales Lead', aggregable: false, width: 150, editable: true, type: 'singleSelect',
        valueOptions: [/*{ value: '', label: 'None' },*/ ...salesTeam.map((salesManager) => { return { value: salesManager.id!, label: salesManager.email! } })],
        renderCell: (params: { value?: string }) => params.value ? <>{salesTeam.find((salesManager) => salesManager.id === params.value)?.email}</> : null,
    };
    const columnContractFile: GridColDef = {
        field: 'contractFile', headerName: 'Contract File', type: 'string', editable: true, aggregable: false, width: 150,
        renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value.url} rel="noreferrer" target="_blank">{params.value.fileName}</a> : null,
        renderEditCell: (params: GridRenderEditCellParams) => (
            <FileUpload params={params} existingFile={params.value} /> // submitFileForm={(fieldValues) => console.log(fieldValues)}
        ),
    };
    const columnTalentPaymentSent: GridColDef = { field: 'talentPaymentSent', headerName: 'Payment Sent', type: 'boolean', aggregable: false, width: 150 };
    const columnTalentPaymentSentDate: GridColDef = { field: 'talentPaymentSentDate', headerName: 'Payment Sent Date', type: 'date', aggregable: false, width: 150, valueGetter: (params) => params.value ? params.value.toDate() : null };
    const columnInvoiceLink: GridColDef = { field: 'invoiceLink', headerName: 'Invoice Link', type: 'string', aggregable: false, width: 150, editable: false, renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value} rel="noreferrer" target="_blank">{params.value}</a> : null };
    const columnActions: GridColDef = {
        field: 'actions', headerName: 'Actions', type: 'actions', width: 80, aggregable: false,
        // TJN TO-DO MAYBE: UPDATE TO official ACTIONS APPROACH...
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.row.bookerId);
            const client: Client | undefined = clients?.find((client) => client.id === booker?.clientId || client.id === params.row.clientId);
            const campaign: Campaign | undefined = campaigns.find((campaign) => campaign.id === params.row.campaignId);
            const curTal = talent?.find((tal) => tal.id === params.row.talentId);

            return <><BookingActionButton disabled={!user?.roles?.admin && !params.row.managerIds?.includes(user?.id)} booking={params.row} booker={booker} client={client} campaign={campaign} talent={curTal} updateDocField={updateDocField} updateDoc={updateDoc} /></>
        }

    };

    ////////////////
    // Column ORDER
    //////////////// 
    const columns: GridColDef[] = [
        columnId,
        columnAirtableId,
        columnTalentName,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnManagerIds] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnClient] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnBooker] : []),
        columnCampaign,
        columnDocMeta,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnCreatedByManager] : []),
        columnMonthDue2,
        columnCurrency,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnPrice] : []),
        columnOverrideMicroRevenue,
        columnExternalCommission,
        ...(user?.roles?.admin || user?.roles?.admin ? [columnOverrideManagerCommission] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnOverrideRemoveCommissions] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnAgentCommission] : []),
        columnStatus,
        columnVideoLink,
        columnBoostCode,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnBookingType] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnPurchaseOrder] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnInvoiceNotes] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnUniportContactEmail] : []),
        ...(user?.roles?.salesTeam || user?.roles?.admin ? [columnSalesLeadId] : []),
        columnContractFile,
        columnTalentPaymentSent,
        columnTalentPaymentSentDate,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnInvoiceLink] : []),
        columnActions,
    ];

    const sumByCurrency: GridAggregationFunction<any, any | null> = {
        getCellValue: ({ row }) => {
            // 1. Don't send more data in here than you need to, as it gets replicated across tons of rows...
            // 2. Costs less memory to send only the source variables to getCellValue and calculate the result values in `apply`
            const miniRow = {
                currency: row.currency,
                price: row.price,
                talentId: row.talentId,
                overrideManagerCommission: row.overrideManagerCommission,
                overrideRemoveCommissions: row.overrideRemoveCommissions,
                overrideMicroRevenue: row.overrideMicroRevenue,
                approvalDate: row.approvalDate,
                // agentCommission: agentCommissionValueGetter({ row: row }),
                // externalCommission: externalCommissionValueGetter({ row: row })
            }
            return miniRow;
        },

        apply: (params) => {
            if (params.values.length === 0) {
                return null;
            }

            let USD = 0;
            let GBP = 0;
            let unknown = 0;

            // Just do this once, not on every loop pass
            const externalCommissionTalent = talent.filter((tal) => tal.externalCommission);

            // For the given field type, get the relevant field value, and sum by each currency...
            for (const miniRow of params.values) {
                let fieldValue = 0;
                if (params.field === 'price' && miniRow.price) {
                    fieldValue = parseFloat(miniRow.price);
                } else if (params.field === 'agentCommission') {
                    fieldValue = agentCommissionValueGetter({ row: miniRow }) || 0;
                } else if (params.field === 'externalCommission') {
                    // This is almost the same as externalCommissionValueGetter...
                    // But we're using the shorter externalCommissionTalent array to improve performance 
                    fieldValue = externalCommissionTalent.find((tal) => tal.id === miniRow.talentId)?.externalCommission || 0;
                }

                if (fieldValue) {
                    if (miniRow.currency === 'USD') {
                        USD += fieldValue;
                    } else if (miniRow.currency === 'GBP') {
                        GBP += fieldValue;
                    } else {
                        unknown += fieldValue;
                    }
                }
            }

            return {
                USD: USD,
                GBP: GBP,
                unknown: unknown,
            }
        },
        // Keep your value formatter separate... seems to help with memory
        // valueFormatter: (params) => params.value,
        valueFormatter: (params) => {
            const USD = params.value?.USD;
            const GBP = params.value?.GBP;
            const unknown = params.value?.unknown;

            let unknownBlock;

            if (unknown) {
                unknownBlock = <Tooltip title={`Total of bookings without currency: ${accounting.formatMoney(unknown, '', 2, ',')}`}><span>&nbsp;*</span></Tooltip>
            }

            return (
                <div className="font-14">
                    {USD ?
                        <div className="flex justify-content-end">
                            <div>$</div>
                            <div className="flex justify-content-between">
                                {accounting.formatMoney(USD, '', 2, ',')}
                                {unknownBlock ? unknownBlock : null}
                            </div>
                        </div>
                        : null
                    }
                    {GBP ?
                        <div className="flex justify-content-end">
                            <div>£</div>
                            <div className="flex justify-content-between">
                                {accounting.formatMoney(GBP, '', 2, ',')}
                                {unknownBlock ? unknownBlock : null}
                            </div>
                        </div>
                        : null
                    }
                </div>
            );
        },
        label: 'money',
    };

    // Don't allow filtering on the second "grouping" version of Month Due
    // https://mui.com/x/react-data-grid/filtering/multi-filters/#one-filter-per-column
    const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
        // console.log(`columns`, columns);
        // console.log(`currentFilters`, currentFilters);

        return columns
            .filter((colDef) => colDef.field !== "__row_group_by_columns_group__")
            .map((column) => column.field);
    };

    return (
        <div className="BookingsPage">
            <Layout gray={true}>
                {/* , paddingTop: '1rem' */}
                <div style={{ display: 'flex', height: 'calc(100vh - 7rem)', width: '100%' }}>
                    <DataGridPremium
                        throttleRowsMs={200}
                        showCellVerticalBorder
                        density="compact"
                        slots={{
                            toolbar: CustomToolbar,
                            booleanCellTrueIcon: CheckBoxOutlined,
                            booleanCellFalseIcon: CheckBoxOutlineBlankOutlined,
                        }}
                        slotProps={{
                            toolbar: {
                                limitToManager,
                                setLimitToManager,
                                limitToLast3Months,
                                setLimitToLast3Months,
                                isManager: user?.roles?.manager
                            },
                            filterPanel: {
                                filterFormProps: {
                                    filterColumns,
                                },
                                // Don't allow the first filter to be the groupable one either...
                                getColumnForNewFilter: ({ columns }) => columns.filter((col) => col.field !== '__row_group_by_columns_group__')[0].field

                            }
                        }}
                        hideFooter
                        // columnBuffer={100}
                        apiRef={apiRef} rows={bookings} columns={columns}
                        isCellEditable={(params) => {
                            const userHasPermission = params.row.managerIds?.includes(user?.id) || user?.roles?.admin;
                            if (params.field === 'price') {
                                return userHasPermission && params.row.status !== 'approved';
                            } else if (params.field === 'overrideMicroRevenue' || params.field === 'overrideManagerCommission') {
                                return params.row.status !== 'approved';
                            } else if (params.field === "salesLeadId") {
                                return user?.roles?.salesTeam;
                            } else {
                                return userHasPermission;
                            }
                        }}

                        processRowUpdate={(newRow, oldRow) => {
                            const changedFields = grailUtil.findChangedObjectKeys(oldRow, newRow);
                            if (Object.keys(changedFields).length === 1) {
                                const field = Object.keys(changedFields)[0];
                                const value = newRow[field];
                                const docId = newRow.id.toString();

                                if (field === 'monthDue2') {
                                    updateDocField("bookings", newRow.id.toString(), field, grailUtil.monthDueToTimestamp(value));
                                } else if (field === 'salesLeadId') {
                                    const airtableSalesLeadId = managers?.find((manager) => manager.id === value)?.managerAirtableId;
                                    updateDoc("bookings", docId, { salesLeadId: value, airtableSalesLeadId: airtableSalesLeadId || "" }, "salesLeadId");
                                } else {
                                    updateDocField("bookings", docId, field, value);
                                    // Automatically update status to posted if valid video link provided...
                                    const status = apiRef.current.getRow(docId || '').status;
                                    if (value && field === 'videoLink' && (status === 'confirmed' || status === 'draft')) {
                                        updateDocField("bookings", docId, "status", "posted");
                                    }
                                }

                            } else if (Object.keys(changedFields).length > 1) {
                                console.log(`changedFields`, changedFields);
                                alert("ERROR!");
                            }

                            return newRow; // must return to actually update row internally
                        }}

                        // rowsPerPageOptions={[100, 500, 1000]}
                        // pagination
                        initialState={{
                            columns: {
                                columnVisibilityModel: user?.managerBookingColumns || {
                                    id: false, // Grail Platform ID
                                    docMeta: false, // Created Date
                                    overrideMicroRevenue: false, // Creator Commission Override
                                    // overrideRemoveCommissions: false,
                                    overrideManagerCommission: false, // Manager Commission Override
                                    externalCommission: false, // External Commission
                                    // bookingType: false,
                                    createdByManager: false, // Created by Grail Team
                                    boostCode: false, // Boost Code
                                    salesLeadId: false, // Sales Lead
                                },
                            },
                            sorting: {
                                // sortModel: [{ field: 'monthDue', sort: 'desc' }], // , { field: 'docMeta', sort: 'desc' }
                                sortModel: user?.managerBookingSorting || [{ field: '__row_group_by_columns_group__', sort: 'desc' }]
                            },
                            filter: {
                                filterModel: user?.managerBookingFilters || {
                                    items: [{ id: 1, field: 'managerIds', operator: 'is', value: user?.id || '' }],
                                    logicOperator: GridLogicOperator.And,
                                },
                            },
                            rowGrouping: {
                                model: ['monthDue2'],
                            },
                            aggregation: {
                                model: {
                                    'price': 'sumByCurrency',
                                    'agentCommission': 'sumByCurrency',
                                },
                            },
                            pinnedColumns: user?.managerBookingPinnedColumns || {},
                        }}
                        aggregationFunctions={{
                            sumByCurrency: sumByCurrency,
                        }}
                        aggregationModel={{
                            'price': 'sumByCurrency',
                            'agentCommission': 'sumByCurrency',
                            'externalCommission': 'sumByCurrency',
                        }}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-toolbarContainer': {
                                padding: '1rem 0',
                            },
                            '& .MuiButton-textPrimary': {
                                height: '2rem',
                                borderRadius: '1rem',
                                padding: '0 0.75rem',
                                color: '#000',
                                border: '1px solid #62edff',
                                margin: '0.25rem 0.5rem 0.25rem 0',
                                '&:hover': {
                                    'backgroundColor': 'rgba(98, 237, 255, 0.3)'
                                }
                            }
                        }}
                        defaultGroupingExpansionDepth={1}
                        // Use JSON.parse/JSON.stringify to handle undefined values...
                        onColumnVisibilityModelChange={(newColumnModel) => user?.id ? updateDocField("users", user?.id, "managerBookingColumns", JSON.parse(JSON.stringify(newColumnModel))) : console.log("")}
                        onFilterModelChange={(newFilterModel) => user?.id ? updateDocField("users", user?.id, "managerBookingFilters", JSON.parse(JSON.stringify(newFilterModel))) : console.log("")}
                        onSortModelChange={(newSortModel) => user?.id ? updateDocField("users", user?.id, "managerBookingSorting", JSON.parse(JSON.stringify(newSortModel))) : console.log("")} //console.log('newSortModel', newSortModel)
                        onPinnedColumnsChange={(newPinnedColumns) => user?.id ? updateDocField("users", user?.id, "managerBookingPinnedColumns", JSON.parse(JSON.stringify(newPinnedColumns))) : console.log("")}
                        onProcessRowUpdateError={(error) => console.log(`error`, error)}
                    // onRowGroupingModelChange={(newGroupingModel) => console.log(`groupingModel`, newGroupingModel)}
                    />
                </div>
            </Layout>
        </div>
    );
}

export default Bookings;