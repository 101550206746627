import './CurrencyCell.scss';

function CurrencyCell(params: any) {
    const currency = params.value?.toLowerCase();

    if (currency === "usd") {
        return <><span className="currency-usd"></span> USD</>
    } else if (currency === "gbp") {
        return <><span className="currency-gbp"></span> GBP</>
    } else {
        return <>{currency}</>
    }
}

export default CurrencyCell;