import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { getListById, updateList, useExchangeRate, useFreshCountries, useFreshLists, useFreshTags, useFreshTalent } from "../services/firebase";
import grailUtil from "../services/grailUtil";
import { List, TalFilter, Talent } from "../shared/types";

//
import Layout from "../components/Layout";
import TalentFilter, { defaultFilter } from "../components/TalentFilter";

//
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function EditList() {
    const { listId } = useParams<{ listId: string }>();

    const talentQuery = useFreshTalent(false, false); // don't allow managers to add hidden/deleted talent to lists, since they'll be seen by bookers
    const rawTalent = talentQuery.flatData;

    const countriesQuery = useFreshCountries();
    const countryList = countriesQuery.flatData.filter((country) => country.liveOnAirtable).map((country) => country.label).sort();
    const tagsQuery = useFreshTags();
    const tagList = tagsQuery.flatData.map((tagDoc) => tagDoc.tag).sort();

    const listsQuery = useFreshLists({});
    const lists = [{ id: "", name: "All", active: true }, ...listsQuery.flatData];

    const poundsToDollars = useExchangeRate();
    const [state, dispatch] = useReducer(listStateReducer, { name: '', talent: [], selectedTalent: {}, filter: { ...defaultFilter, showCampaignTypeSelect: true } });
    const { name, talent, selectedTalent, filter } = state;

    // EFFECT: calculateTalentWithPrices
    useEffect(() => {
        // console.log("EFFECT: calculateTalentWithPrices");
        const talentWithPrice = calculateTalentWithPrices(rawTalent, poundsToDollars, filter);
        dispatch({ type: 'setStatePartial', payload: { talent: talentWithPrice } });
    }, [rawTalent, poundsToDollars, filter]);

    // EFFECT: List
    useEffect(() => {
        if (listId) {
            getListById(listId).then((list) => dispatch({ type: 'setList', list }))
        }
    }, [listId]);

    async function handleSave() {
        if (listId) {
            const res = await updateList({
                name,
                id: listId,
                talentIds: Object.keys(selectedTalent).map((talId) => talId),
                platform: filter.campaignPlatform
            });

            if (res.success) {
                dispatch({ type: 'setStatePartial', payload: { showAlert: 'success' } })
            }

            console.log(`res`, res);
        }

    }

    return (
        <Layout gray={true}>
            <div className="row-fluid p-0">
                <div className="col-md-8 p-100">
                    {name ? (
                        <TalentFilter
                            talent={talent}
                            selectedTalent={selectedTalent}
                            updateFilter={(filterPartial) => dispatch({ type: 'updateFilter', filterPartial })}
                            filter={filter}
                            countryList={countryList}
                            tagList={tagList}
                            lists={lists}
                            handleAdd={(id, bidPrice) => dispatch({ type: 'addTalent', id, bidPrice })}
                            handleRemove={(id) => dispatch({ type: 'removeTalent', id })}
                        />
                    ) : <CircularProgress size="1.5rem" />}

                </div>
                <div className="col-md-4 bg-white p-100">
                    <Alert onClose={() => dispatch({ type: 'setStatePartial', payload: { showAlert: undefined } })} sx={{ marginBottom: '1rem', display: state.showAlert ? 'flex' : 'none' }}>
                        Your changes have been saved!
                    </Alert>

                    <button onClick={handleSave} className="btn btn-primary">Save</button>
                    <div className="form-group mt-100">
                        <label className="required">List Name</label>
                        <input name="name" id="name" value={name} onChange={(e) => dispatch({ type: 'setStatePartial', payload: { name: e.target.value } })} />
                    </div>

                    <div className="form-group mt-100">
                        <label className="required">Platform</label>
                        <div className="flex align-items-center">
                            {filter.campaignPlatform === "TikTok" ? (<><img className="true-color-icon mr-75" src="/icon-tiktok.svg" alt="TikTok" /> TikTok</>) : null}
                            {filter.campaignPlatform === "Instagram" ? (<><img className="true-color-icon mr-75" src="/icon-instagram.svg" alt="Instagram" /> Instagram</>) : null}
                            {filter.campaignPlatform === "YouTube" ? (<><img className="true-color-icon mr-75" src="/icon-youtube.svg" alt="YouTube" /> YouTube</>) : null}
                        </div>
                    </div>

                    <div className="form-group mt-100">
                        <label className="required">Talent</label>
                    </div>

                    {Object.keys(selectedTalent).map((talId) => {
                        const tal = talent.find((tal) => tal.id === talId);
                        return (
                            <div className="TalentMini" key={talId}>
                                <div className="flex">
                                    <div className="TalentImg" style={{ backgroundImage: `url(${tal?.imgUrl ? tal.imgUrl : 'talent-default.png'})` }}></div>
                                    <div>
                                        <div className="mb-25"><strong>@{tal?.tiktokUser}</strong></div>
                                    </div>
                                </div>
                                <div className="ml-25">
                                    <IconButton onClick={() => dispatch({ type: 'removeTalent', id: talId })} aria-label="remove">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </Layout>
    )
}

type SelectedTalent = { [key: string]: number };

interface ListState {
    name: string,
    filter: TalFilter,
    selectedTalent: SelectedTalent,
    talent: Talent[],
    list?: List,
    showAlert?: 'success' | 'error',
    // totalTiktokFollowers: number, // depends on selectedTalent
    // totalInstagramFollowers: number, // depends on selectedTalent,
    // totalPrice: number, // depends on selectedTalent
}

type ListAction =
    { type: 'setStatePartial', payload: Partial<ListState> } |
    { type: 'setList', list: List } |
    { type: 'addTalent', id: string, bidPrice: number, isRec?: boolean } |
    { type: 'removeTalent', id: string } |
    { type: 'updateFilter', filterPartial: Partial<TalFilter> };

function listStateReducer(state: ListState, action: ListAction) {
    switch (action.type) {
        case 'setStatePartial': {
            return { ...state, ...action.payload } as ListState;
        }
        case 'updateFilter': {
            console.log("newFilter", {
                ...state.filter,
                ...action.filterPartial
            });
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filterPartial
                } as TalFilter
            };
        }
        case 'setList': {
            const list = action.list;
            const selectedTalent: SelectedTalent = (list.talentIds || []).reduce((acc, cv) => Object.assign(acc, { [cv]: 1 }), {});

            return {
                ...state,
                name: list.name,
                selectedTalent,
                filter: {
                    ...state.filter,
                    campaignPlatform: list.platform || "TikTok"
                }
            };
        }
        case 'addTalent': {
            const { id, bidPrice } = action;
            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            newSelectedTalent[id] = bidPrice;
            return { ...state, selectedTalent: newSelectedTalent };
        }
        case 'removeTalent': {
            const { id } = action;
            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            delete newSelectedTalent[id];
            return { ...state, selectedTalent: newSelectedTalent };
        }
    }
}

function calculateTalentWithPrices(talent: Talent[], poundsToDollars: number, filter: TalFilter) {
    //console.log("FUNCTION: recalculateTalentPrices", talent.length, poundsToDollars);

    let talentWithPrice = talent.map((tal: Talent) => {
        const localPrices = grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency);
        const defaultPrice = grailUtil.getDefaultGuidePrice(localPrices || {}, filter.campaignPlatform, !!filter.showSongPrice);

        return {
            ...tal,
            defaultPrice: defaultPrice,
            localPrices: grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency)
        } as Talent
    });

    return talentWithPrice;
}