import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { UserContext, getCampaignsByBookerId, getUserById } from "../services/firebase";
import { Campaign, User } from "../shared/types";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";

import Layout from "../components/Layout";
import './Campaigns.scss';


import ArrowIcon from '@mui/icons-material/ArrowForwardIos';

const Campaigns: React.FC<any> = () => {
    const { uid } = useParams<{ uid: string }>();
    const authenticatedUser = useContext(UserContext);
    const [user, setUser] = useState<User>();
    const [userCampaigns, setUserCampaigns] = useState<Campaign[]>();
    const navigate = useNavigate();

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (authenticatedUser) {
                const theUserCampaigns = await getCampaignsByBookerId(uid || authenticatedUser.id!, false);
                setUserCampaigns(theUserCampaigns.sort((c1, c2) => (c2.docMeta?.createTime ? c2.docMeta?.createTime.toDate().getTime() : 0) - (c1.docMeta?.createTime ? c1.docMeta?.createTime.toDate().getTime() : 0)));
            }

            if (uid) {
                const newUser = await getUserById(uid);
                setUser(newUser);
            }
        }
    }, [authenticatedUser, uid]);

    return (
        <Layout gray={true}>
            <section className="CampaignsPage">
                {user ? (
                    <h1 className="mt-200 mb-200">{user.firstName} {user.lastName}'s Campaigns</h1>
                ) : (<h1 className="mt-200 mb-200">My Campaigns</h1>)}


                {userCampaigns?.length ? userCampaigns.map((campaign) => (
                    <div className="card flex align-items-center justify-content-between" key={campaign.id} onClick={() => navigate(`/campaign/${campaign.id}`)}>
                        <div>
                            <div className="font-weight-bold font-18 mb-25">{campaign.campaignName}</div>
                            {campaign.docMeta?.createTime ? (
                                <div>
                                    <span className="label">Created:</span> {campaign.docMeta?.createTime ? dayjs(campaign.docMeta.createTime.toDate()).format('MMM DD, YYYY') : null}
                                </div>
                            ) : null}

                            {campaign?.bookings ? (
                                <div><span className="label">Bookings:</span> {campaign?.bookings ? Object.keys(campaign.bookings).length : null}</div>
                            ) : null}
                            <div></div>
                        </div>
                        <div>
                            <ArrowIcon />
                        </div>
                    </div>
                )) : <div>No Recent Campaigns</div>}
            </section>
        </Layout>
    )
}

export default Campaigns;