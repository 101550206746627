import Layout from '../components/Layout';

export function RecommendTest() {
    return (
        <>
            <Layout>

            </Layout>
        </>
    );
}