import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUserById, updateUser, createClient, useFreshClients } from '../services/firebase';
import { User, Client, AutocompleteOption } from '../shared/types';
import { Controller, useForm, FieldValues } from "react-hook-form";
import Alert from "@mui/material/Alert";

//
import Layout from '../components/Layout';
import ClientSelect from '../components/ClientSelect';


function EditUser() {
    const { uid } = useParams<{ uid: string }>();
    const [user, setUser] = useState<User>();
    const [newClient, setNewClient] = useState<Client>();
    const [clientOptions, setClientOptions] = useState<AutocompleteOption<Client>[]>([]);
    const [showAlert, setShowAlert] = useState(<></>);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

    const clientsQuery = useFreshClients();
    const clients = clientsQuery.flatData;

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            console.log("getInitialProps!");

            if (uid) {
                let newUser = await getUserById(uid);
                setUser(newUser);

                const newClientOptions = clients.map((client) => {
                    return {
                        val: client,
                        label: client.name + ' *** ' + client.airtableId + ' *** ' + client.id
                    }
                });

                setClientOptions(newClientOptions);

                if (newUser?.clientId) {
                    const client = clients.find((client) => client.id === newUser.clientId);
                    setValue('clientObj', client);
                }
            }

        }
    }, [uid, setValue, clients]);

    async function saveUser(formData: FieldValues) {
        Object.keys(formData).forEach(key => {
            if (formData[key] === undefined) {
                delete formData[key];
            }
        });

        if (newClient) {
            const res = await createClient({
                name: newClient.name,
                primaryBookerEmail: formData.email,
                primaryBookerId: uid,
            });

            if (res.success) {
                formData.clientId = res.result;
            }
        } else {
            formData.clientId = formData.clientObj?.id;
        }
        delete formData.clientObj; // We don't want the full Client obj in the booker...


        const res = await updateUser({ ...formData, id: uid });

        if (res.success) {
            setShowAlert(<Alert className="mb-100" onClose={() => setShowAlert(<></>)} severity="success">Your changes have been saved!</Alert>)
        } else {
            console.log(res.error);
            setShowAlert(<Alert className="mb-100" onClose={() => setShowAlert(<></>)} severity="error">There was an issue saving your changes... {JSON.stringify(res.error)}</Alert>)
        }

        console.log(res);
    }

    return (
        <Layout>
            <div className="container mt-200 mb-200">
                {showAlert}

                {user ? (
                    <form onSubmit={handleSubmit(saveUser)}>
                        <div className="flex flex-wrap">
                            <div className={`flex-1-0-auto mr-50 form-group mb-100 ${errors.firstName ? 'error' : ''}`}>
                                <label className="required">First Name</label>
                                <input placeholder="First Name" {...register("firstName", { required: true, value: user.firstName })} />
                                {errors.firstName && <div className="error-msg">First name is required.</div>}
                            </div>

                            <div className={`flex-1-0-auto mr-50 form-group mb-100 ${errors.lastName ? 'error' : ''}`}>
                                <label className="required">Last Name</label>
                                <input placeholder="Last Name" {...register("lastName", { required: true, value: user.lastName })} />
                                {errors.lastName && <div className="error-msg">Last name is required.</div>}
                            </div>
                        </div>

                        <div className={`form-group mb-100 ${errors.email ? 'error' : ''}`}>
                            <label className="required">Email</label>
                            <input placeholder="Email" disabled {...register("email", { required: true, value: user.email })} />
                            {errors.email && <div className="error-msg">Email is required.</div>}
                        </div>

                        <div className={`form-group mb-100 ${errors.company ? 'error' : ''}`}>
                            <label className="required">Client</label>

                            <Controller
                                control={control}
                                name="clientObj"
                                render={({
                                    field: { onChange, value },
                                }) => (
                                    <ClientSelect disabled={Boolean(user?.clientId)} value={value} clientOptions={clientOptions} onChange={(e, v) => {
                                        if (typeof v === 'string') {
                                            //
                                        } else if (v?.val) {
                                            onChange(v?.val);
                                            // onChange(v);
                                        } else if (v?.inputValue) {
                                            setNewClient({ name: v.inputValue });
                                        }
                                    }} />
                                )}
                            />
                        </div>

                        <div className="form-group mb-100">
                            <label className="required">Campaign Type</label>
                            <div className="hint">What type of promotions do they primarily run?</div>
                            <select className="mb-100" required {...register("campaignType", { required: true, value: user.campaignType })}>
                                <option value="" disabled>Choose Type...</option>
                                <option value="Song">Song Promos</option>
                                <option value="Brand">Brand Promos</option>
                                <option value="Multi">Both (They work at an agency)</option>
                            </select>
                        </div>

                        <div className="form-group mb-100">
                            <label>Hide From Booker Selection?</label>
                            <div className="hint">Prevents booker from appearing when managers search for bookers when logging a new deal.</div>
                            <div className="flex">
                                <input type="checkbox" {...register("hideBooker", { value: user.hideBooker })} /> <span>Hide</span>
                            </div>
                        </div>

                        <div className={`form-group mb-100 ${errors.phone ? 'error' : ''}`}>
                            <label>Phone Number</label>
                            <input placeholder="Phone" {...register("phone", { required: false, value: user.phone })} />
                            {errors.phone && <div className="error-msg">Phone Number is required.</div>}
                        </div>

                        <input className="btn btn-red" type="submit" value="Save User" />

                    </form>
                ) : null}
            </div>
        </Layout>
    );
}

export default EditUser;