import { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { updateClient, getClientById, sendNewClientForm, UserContext } from '../services/firebase';
import { Client } from '../shared/types';
import { useForm, FieldValues, Controller } from "react-hook-form";
import * as Sentry from "@sentry/react";

// Components
import CountrySelect from '../components/CountrySelect';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import LayoutOnboarding from '../components/LayoutOnboarding';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import SectorSelect from '../components/SectorSelect';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '1px solid #DDD',
    boxShadow: 24,
    p: 4,
    outline: 'none'
};

function EditClient(props: { managerMode?: boolean, adminMode?: boolean }) {
    const { clientId } = useParams<{ clientId: string }>();
    const [client, setClient] = useState<Client>();
    const [submissionStatus, setSubmissionStatus] = useState<'default' | 'pending' | 'success' | 'error'>('default');
    const [errorCode, setErrorCode] = useState('');
    const { register, control, handleSubmit } = useForm(); // , formState: { errors }
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [clientSendStatus, setClientSendStatus] = useState<'default' | 'pending' | 'success' | 'error'>('default');
    const user = useContext(UserContext);
    const location = useLocation();

    const managerMode = props.managerMode || user?.roles?.manager;

    useEffect(() => {
        if (managerMode) {
            getInitialProps();
        } else {
            setClient({ id: clientId, name: '' });
        }
        async function getInitialProps() {
            if (clientId) {
                let newClient = await getClientById(clientId);
                setClient(newClient);
            }
        }
    }, [clientId, props, managerMode]);

    async function submitForm(formData: FieldValues) {
        console.log(formData);

        const newClient = {
            id: clientId,
            name: formData.name,
            nameRegistered: formData.nameRegistered,
            nameTrading: formData.nameTrading,
            address: {
                ...formData.address,
                country: formData.address.country?.label || formData.address?.country || '',
            },
            billingEmail: formData.billingEmail,
            billingPhone: formData.billingPhone,
            companySector: formData.companySector,
            orgNumber: formData.orgNumber,
            vatNumber: formData.vatNumber,

            clientWideSettings: {
                ...(formData.clientWideSettings?.paymentTerms?.termsAccepted && {
                    paymentTerms: {
                        termsAccepted: formData.clientWideSettings.paymentTerms.termsAccepted,
                        firstName: formData.clientWideSettings.paymentTerms.firstName,
                        lastName: formData.clientWideSettings.paymentTerms.lastName,
                        dateAccepted: new Date(),
                    }
                }),

                // Do write blank strings, but don't overwrite if undefined
                ...(typeof formData.clientWideSettings?.currency === 'string' && {
                    currency: formData.clientWideSettings?.currency,
                }),

                // These will be undefined if it's a non-admin editing...
                ...(props.adminMode && {
                    batchInvoices: formData.batchInvoices ? true : false,
                })
            },

            // These will be undefined if it's a client editing...
            ...(managerMode && {
                requiresInvoiceNotes: formData.requiresInvoiceNotes,
                requiresPurchaseOrder: formData.requiresPurchaseOrder, // otherwise true/false
                requiresUniportEmail: formData.requiresUniportEmail, // otherwise true/false
                invoiceBothBookerAndClient: formData.invoiceBothBookerAndClient, // otherwise true/false
                netPaymentTerms: parseInt(formData.netPaymentTerms || 0),
            }),
        }

        // never use with a totally blank object, because Firebase will overwrite whole object in this case, even with merge
        if (Object.keys(newClient.clientWideSettings).length < 1) {
            console.log("DELETING EMPTY clientWideSettings");
            delete newClient.clientWideSettings;
        }

        console.log(`NEW CLIENT`, newClient);

        const res = await updateClient(newClient);

        if (res.success) {
            setSubmissionStatus("success");
        } else {
            if (res.error.code === "permission-denied") {
                setErrorCode("previously-completed");
            } else {
                setErrorCode(res.error.code);
                Sentry.captureException(res.error);
            }

            setSubmissionStatus("error");
        }
    }

    async function sendToClient(event: React.SyntheticEvent) {
        event.preventDefault();
        const target = event.target as typeof event.target & { email: { value: string } };
        console.log(target.email.value);
        setClientSendStatus('pending');


        const res = await sendNewClientForm(clientId || '', target.email.value);

        if (res.success) {
            setClientSendStatus('success');
        } else {
            setClientSendStatus('error');
            console.log(res.error);
        }
    }

    function copyClientLink(clientId: string) {
        navigator.clipboard.writeText(`https://app.grail-talent.com/new-client/${clientId}`);
        setSnackbarOpen(true);
    }

    function handleModalClose() {
        setClientSendStatus('default');
        setModalOpen(false);
    }


    if (managerMode && location.pathname.includes('/new-client/')) {
        console.log(`Show other thing`);

        return (
            <LayoutOnboarding>
                <div className="NewClient">
                    This form is only for use by new clients.

                    {client?.name ? (
                        <><p>To update the details for {client.name}, <Link to={`/edit-client/${client.id}`}>click here</Link>.</p> <p className='font-18'><u>Do <strong>NOT</strong></u> use the link above to create a new client!</p></>
                    ) : null}
                </div>
            </LayoutOnboarding>
        )
    }

    return (
        <div className="NewClient">
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div className="font-weight-bold font-24 mb-100">Send New Client Form to Client</div>

                    {clientSendStatus === 'pending' ? (
                        <div className="text-center mt-300 mb-300">
                            <CircularProgress />
                        </div>
                    ) : null}

                    {clientSendStatus === 'default' || clientSendStatus === 'error' ? (
                        <>
                            {clientSendStatus === 'error' ? (<>
                                <Alert className='mt-100 mb-100 text-left' severity="error">
                                    <div className="mb-100">Something went wrong. Please try again.</div>
                                </Alert>
                            </>) : null}
                            <form onSubmit={(e) => sendToClient(e)}>
                                <div className="form-group mb-100">
                                    <label className="required">Client Email Address</label>
                                    <input name="email" type="email" required />
                                </div>

                                <input className="btn btn-primary" type="submit" value="Send Form" />
                            </form>
                        </>
                    ) : null}

                    {clientSendStatus === 'success' ? (<>
                        <Alert className='mt-100 mb-100 text-left' severity="success">
                            <div className="mb-100">Form was successfully sent!</div>
                        </Alert>
                    </>) : null}
                </Box>

            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setSnackbarOpen(false)}
                message="Link Copied"
            />

            <LayoutOnboarding>
                {clientId === 'undefined' || clientId === 'null' ? (
                    <>This link is invalid. If you think this is an error, please contact Grail with this URL and a description of how you arrived here.</>
                ) : null}
                {clientId && clientId !== 'undefined' && clientId !== 'null' && client ? (
                    <>
                        {submissionStatus === 'pending' ? (
                            <div className="text-center mt-300 mb-300">
                                <CircularProgress />
                            </div>
                        ) : null}

                        {
                            managerMode ? (
                                <>
                                    <div className="mb-100">
                                        <div className="mt-50 mb-50"><button className="btn btn-sm btn-primary" onClick={() => setModalOpen(true)}>Send New Client Form to Client</button></div>
                                        <div className="mt-50 mb-50"><button className="btn btn-sm btn-red" onClick={() => copyClientLink(clientId)}>Copy Link to Client Form</button></div>
                                    </div>
                                </>
                            ) : null
                        }



                        {/* JUST HIDE IT when not 'default' or 'error', don't destroy it, in order to preserve form state */}
                        <form className={submissionStatus === 'default' || submissionStatus === 'error' ? 'card' : 'hidden'} onSubmit={handleSubmit(submitForm)} >
                            {
                                !managerMode ? (
                                    <div>
                                        <div className="form-group mb-100">
                                            <label className="required">First Name</label>
                                            <input type="text" required {...register("clientWideSettings.paymentTerms.firstName", { required: true })} />
                                        </div>
                                        <div className="form-group mb-100">
                                            <label className="required">Last Name</label>
                                            <input type="text" required {...register("clientWideSettings.paymentTerms.lastName", { required: true })} />
                                        </div>

                                        <hr />
                                    </div>
                                ) : null
                                // (
                                //     <div className="mb-300 text-left">
                                //         <strong>NOTE:</strong> Only clients and admins can edit client details. Think something needs changing? <a className="alternate" href={`mailto:lyonel@grail-talent.com?subject=${client?.name} Client Info Inquiry`} target="_blank" rel="noreferrer">Click here</a> to mail the operations team
                                //     </div>
                                // )
                            }

                            <div className="form-group mb-100">
                                <label className="required">Company Name</label>
                                <input type="text" required {...register("name", { required: true, value: client?.name })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Registered Name (If Different)</label>
                                <input type="text" {...register("nameRegistered", { value: client?.nameRegistered })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Trading Name (If Different)</label>
                                <input type="text" {...register("nameTrading", { value: client?.nameTrading })} />
                            </div>

                            <hr />

                            <div className="form-group mb-100">
                                <label className="required">Company Sector</label>
                                <Controller
                                    control={control}
                                    name="companySector"
                                    defaultValue={client?.companySector || ''}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <SectorSelect onChange={onChange} value={value} required={true} />
                                    )}
                                />
                            </div>

                            <hr />

                            <div className="form-group mb-100">
                                <label className="required">Address Line 1</label>
                                <input type="text" {...register("address.addressLine1", { required: true, value: client?.address?.addressLine1 })} required />
                            </div>

                            <div className="form-group mb-100">
                                <label>Address Line 2</label>
                                <input type="text" {...register("address.addressLine2", { value: client?.address?.addressLine2 })} />
                            </div>

                            <div className="form-group mb-100">
                                <label className="required">City/Town</label>
                                <input type="text" {...register("address.city", { required: true, value: client?.address?.city })} required />
                            </div>

                            <div className="form-group mb-100">
                                <label className="required">State/Region</label>
                                <input type="text" {...register("address.state", { required: true, value: client?.address?.state })} required />
                            </div>

                            <div className="form-group mb-100">
                                <label className="required">Zip/Postal Code</label>
                                <input type="text" {...register("address.postCode", { required: true, value: client?.address?.postCode })} required />
                            </div>

                            <div className="form-group mb-100">
                                <label className="required">Country</label>
                                <Controller
                                    control={control}
                                    name="address.country"
                                    defaultValue={client?.address?.country || ''}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <CountrySelect onChange={onChange} value={value} required={true} readOnly={Boolean(managerMode && !props.adminMode && client?.address?.country)} />
                                    )}
                                />

                                {managerMode && !props.adminMode && client?.address?.country ? <div className="text-left mt-100 font-12">To prevent billing issues, once completed the country can only be changed by an admin. Contact lyonel@grail-talent.com for more info.</div> : null}
                            </div>

                            <hr />

                            <div className="text-left mb-100 font-12">If you don't have a billing department, enter your own info below instead.</div>

                            <div className="form-group mb-100">
                                <label className="required">Billing/Finance Dep. Email</label>
                                <input type="email" {...register("billingEmail", { required: true, value: client?.billingEmail })} required />
                            </div>

                            <div className="form-group mb-100">
                                <label className={managerMode ? '' : 'required'}>Billing/Finance Dep. Phone Number</label>
                                <input type="text" {...register("billingPhone", { required: managerMode ? false : true, value: client?.billingPhone })} required={managerMode ? false : true} />
                            </div>

                            <hr />

                            <div className="form-group mb-100">
                                <label>VAT Number (UK/EU Only)</label>
                                <input type="text" {...register("vatNumber", { value: client?.vatNumber })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Organization Number (if different)</label>
                                <input type="text" {...register("orgNumber", { value: client?.orgNumber })} />
                            </div>

                            {!managerMode ? (
                                <div className="form-group mb-100">
                                    <label className="required">Do you agree to the&nbsp;<a href="https://grail-talent.com/payment-terms" target="_blank" rel="noreferrer">Grail Talent Payment terms</a>?</label>
                                    <div className="flex">
                                        <input type="checkbox" {...register("clientWideSettings.paymentTerms.termsAccepted", { required: true, })} required /> <span>Yes, I agree</span>
                                    </div>
                                </div>
                            ) : null}

                            {managerMode ? (
                                <>
                                    <hr />
                                    <div className="form-group mb-100">
                                        <label>Lock Currency</label>

                                        <select {...register("clientWideSettings.currency", { value: client?.clientWideSettings?.currency })}>
                                            <option value="">Both USD/GBP Allowed</option>
                                            <option value="USD">USD</option>
                                            <option value="GBP">GBP</option>
                                        </select>

                                    </div>

                                    <div className="form-group mb-100">
                                        <label>Approval Requirements</label>
                                        <div className="flex">
                                            <input type="checkbox" {...register("requiresInvoiceNotes", { value: client?.requiresInvoiceNotes })} />
                                            <span className="ml-50">Requires Invoice Notes</span>
                                        </div>
                                        <div className="flex">
                                            <input type="checkbox" {...register("requiresPurchaseOrder", { value: client?.requiresPurchaseOrder })} />
                                            <span className="ml-50">Requires Purchase Order</span>
                                        </div>

                                        <div className="flex">
                                            <input type="checkbox" {...register("requiresUniportEmail", { value: client?.requiresUniportEmail })} />
                                            <span className="ml-50">Requires Uniport Email</span>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="form-group mb-100">
                                        <label>Payment Terms</label>
                                        <input type="number" {...register("netPaymentTerms", { value: client?.netPaymentTerms })} />
                                    </div>

                                    <hr />

                                    <div className="form-group mb-100">
                                        <label>Invoice Both Booker and Client</label>
                                        <div className="flex">
                                            <input type="checkbox" {...register("invoiceBothBookerAndClient", { value: client?.invoiceBothBookerAndClient })} />
                                            <span className="ml-50 text-left">Send Invoice to Both Billing Email and Booker Email</span>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            {props.adminMode ? (
                                <>
                                    <div className="form-group mb-100">
                                        <label>Admin Settings</label>

                                        <div className="flex">
                                            <input type="checkbox" {...register("batchInvoices", { value: client?.clientWideSettings?.batchInvoices })} />
                                            <span className="ml-50">Batch Invoices</span>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            {submissionStatus === 'error' ? (
                                <>
                                    {errorCode === 'previously-completed' ? (
                                        <Alert className='mt-100 mb-100 text-left' severity="info">
                                            <div className="mb-100">Another user has already completed this form.</div>
                                            <div className="mb-100">If Grail already has your company info, you can safely close this form. If you think this is an error, please contact your Grail account manager.</div>
                                        </Alert>
                                    ) : (
                                        <Alert className='mt-100 mb-100 text-left' severity="error">
                                            <div className="mb-100">An error occured. Please try submitting the form again.</div>
                                            <div>If this error persists, please contact your Grail account manager and provide this code: <strong>{errorCode}</strong></div>
                                        </Alert>
                                    )}
                                </>
                            ) : null}

                            <input className="btn btn-primary btn-block mt-200" type="submit" value={managerMode ? "Save Client Info" : "Save My Info"} />
                        </form>

                        {submissionStatus === 'success' ? (
                            <div className="card">
                                Thanks for submitting your info. You can safely close this window.
                            </div>
                        ) : null}
                    </>
                ) : null}
            </LayoutOnboarding>
        </div>
    );
}

export default EditClient;