import React from 'react';
import './TalentMini.scss';
import grailUtil from '../services/grailUtil';
import { Talent } from '../shared/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface TalentMiniProps {
    tal: Talent,
    currency: string,
    handleRemove: (id: string) => any,
    price: number,
}

const TalentMini: React.FC<any> = (props: TalentMiniProps) => {
    // const tal = props.tal;
    // const currency = props.currency;
    // // const remove = props.handleRemove;
    const { tal, currency, handleRemove, price } = props;

    return (
        <div className="TalentMini">
            <div className="flex">
                <div className="TalentImg" style={{ backgroundImage: `url(${tal.imgUrl ? tal.imgUrl : 'talent-default.png'})` }}></div>
                <div>
                    <div className="mb-25"><strong>@{tal.tiktokUser}</strong> - {grailUtil.getCurrencySymbol(currency)}{price.toLocaleString()}</div>
                    <div className="flex flex-wrap">
                        {tal.ttFollowers ? (
                            <div className="flex mr-150">
                                <div className="icon-tiktok icon-black icon-20 mr-50"></div>
                                <div>{grailUtil.abbreviateNumber(tal.ttFollowers)}</div></div>
                        ) : null}

                        {tal.igFollowers ? (
                            <div className="flex">
                                <div className="icon-instagram icon-red icon-20 mr-50"></div> {grailUtil.abbreviateNumber(tal.igFollowers)}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="ml-25">
                <IconButton onClick={() => handleRemove(tal.id)} aria-label="remove">
                    <CloseIcon />
                </IconButton>
            </div>
        </div >
    )
}

export default TalentMini;