import React, { useState } from 'react';
import './TalentCard.scss';
import grailUtil from '../services/grailUtil';
import { Talent, User } from '../shared/types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import modalStyle from '../theme/mui-styles/modal';

interface RosterTalentCardProps {
    tal: Talent,
    managerArr?: User[],
}

const RosterTalentCard: React.FC<any> = (props: RosterTalentCardProps) => {
    const { tal } = props; // showSongPrice, selectedTalent, isManager
    const [statsModalOpen, setStatsModalOpen] = useState(false);

    return (
        <div className="TalentCard" key={tal.id}>
            <div className="TalentImg" style={{ backgroundImage: `url(${tal.imgUrl ? tal.imgUrl : '/talent-default.png'})` }}></div>

            <div className="core">
                <div className="primary">@{tal.tiktokUser || tal.instagramUser || tal.youtubeUser}</div>
                <div className="talentName">{tal.name}</div>
                <div className="label">{tal.country}</div>
            </div>
            <div className="stats">
                {tal.ttFollowers ? (
                    <div className="statsItem">
                        <a href={`https://tiktok.com/@${tal.tiktokUser?.toLowerCase()}`} target="_blank" rel="noreferrer">
                            <div className="icon-tiktok icon-black icon-32 mr-50"></div>
                        </a>
                        <div>
                            <div>{grailUtil.abbreviateNumber(tal.ttFollowers)}</div>
                            <div className="label">Followers</div>
                        </div>
                    </div>
                ) : null}
                {tal.instagramUser ? (
                    <div className="statsItem">
                        <a href={`https://instagram.com/${tal.instagramUser}`} target="_blank" rel="noreferrer">
                            <div className="icon-instagram icon-red icon-32 mr-50"></div>
                        </a>
                        {tal.igFollowers ? (
                            <div>
                                <div>{grailUtil.abbreviateNumber(tal.igFollowers)}</div>
                                <div className="label">Followers</div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <button className="alternate-clean link-button text-uppercase font-12" onClick={() => setStatsModalOpen(true)}>View Full Stats</button>
            </div>


            <div className="price">
                <div className="mb-25">
                    <span className="label mr-25">Song Price:</span>
                    <strong>{tal.currency ? grailUtil.getCurrencySymbol(tal.currency) : null}{tal.prices?.songPriceTikTok?.toLocaleString()}</strong>
                </div>
                <div>
                    <span className="label mr-25">Brand Price:</span>
                    <strong>{tal.currency ? grailUtil.getCurrencySymbol(tal.currency) : null}{tal.prices?.brandPriceTikTok?.toLocaleString()}</strong>
                    {/* <input className="customBid" defaultValue={selectedTalent[tal.id] ? price : ""} placeholder={price} type="number" onChange={(e) => handlePriceChange(e.target)} /> */}
                </div>
            </div>

            <div className="contentTags">
                {tal.category === 'Micro-influencer' ? (
                    <div className="contentTag contentTagBlack">
                        Micro-Influencer
                    </div>
                ) : null}
                {tal.contentTags?.map((tag: string, idx: number) => (
                    <div className="contentTag" key={tag + "-" + idx}>{tag}</div>
                ))}
            </div>

            <div className="p-100">
                {tal.managers?.length ? (<div className="mb-100">
                    <div className="label">Managed By</div>
                    {tal.managers.map((manager) => <div key={manager.id}>{manager.email}</div>)}
                </div>) : null}
                {tal.hideUser ? <div className="font-14 font-red text-uppercase">Hidden from Clients</div> : null}
                {tal.deleted ? <div className="font-14 font-red text-uppercase">Deleted from Platform</div> : null}


                <a className="display-block mt-100" href={`/edit-talent/${tal.id}`} target="_blank" rel="noreferrer">View/Edit Details</a>
            </div>

            <Modal open={statsModalOpen} onClose={() => setStatsModalOpen(false)}>
                <Box sx={{ ...modalStyle, padding: "0.75rem" }}>
                    {
                        tal.tiktokUser ? (
                            <div className="stats stats-row">
                                <div className="statsItem">
                                    <a href={`https://tiktok.com/@${tal.tiktokUser?.toLowerCase()}`} target="_blank" rel="noreferrer">
                                        <img className="true-color-icon mr-50" src="/icon-tiktok.svg" alt="YouTube" />
                                    </a>
                                    <div>
                                        <div>{tal.ttFollowers ? grailUtil.abbreviateNumber(tal.ttFollowers) : '-'}</div>
                                        <div className="label">Followers</div>
                                    </div>
                                </div>
                                {tal.ttStats ? (
                                    <>
                                        <div className="statsItem">
                                            <div></div>
                                            <div>
                                                <div>{tal.ttStats.averageViews ? <div>{Math.round(tal.ttStats.averageViews).toLocaleString()}</div> : '-'}</div>
                                                <div className="label">Avg. Views / Vid</div>
                                            </div>
                                        </div>

                                        <div className="statsItem">
                                            <div></div>
                                            <div>
                                                <div>{tal.ttStats.engagementRate ? <div>{Math.round(tal.ttStats.engagementRate * 1000) / 10}%</div> : '-'}</div>
                                                <div className="label">Engagement Rate</div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}


                            </div>
                        ) : null
                    }

                    {tal.instagramUser ? (
                        <div className="stats stats-row">
                            <div className="statsItem">
                                <a href={`https://instagram.com/${tal.instagramUser}`} target="_blank" rel="noreferrer">
                                    <img className="true-color-icon mr-50" src="/icon-instagram.svg" alt="YouTube" />
                                </a>
                                {tal.igFollowers ? (
                                    <div>
                                        <div>{grailUtil.abbreviateNumber(tal.igFollowers)}</div>
                                        <div className="label">Followers</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {tal.youtubeUser ? (
                        <div className="stats stats-row">
                            <div className="statsItem">
                                <a href={"https://www.youtube.com/@" + tal.youtubeUser} target="_blank" rel="noreferrer">
                                    <img className="true-color-icon mr-50" src="/icon-youtube.svg" alt="YouTube" />
                                </a>
                                {tal.ytFollowers ? (
                                    <div>
                                        <div>{grailUtil.abbreviateNumber(tal.ytFollowers)}</div>
                                        <div className="label">Subscribers</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </Box>
            </Modal>

        </div>
    );
}

export default RosterTalentCard;