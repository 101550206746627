import React, { useState } from 'react';

import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ChipSelect.scss';

interface ChipRangeProps {
    label: React.ReactNode,
    min: number,
    minLabel?: React.ReactNode,
    max: number,
    maxLabel?: React.ReactNode,
    onChange: (newFilter: ({ lower: number, upper: number } | undefined)) => unknown,
    step: number,
    value: number[],
}

function ChipRange(props: ChipRangeProps) {
    const [anchorEl, setAnchorEl] = useState<Element>();
    const open = Boolean(anchorEl);
    const { label, min, minLabel, max, maxLabel, onChange, step, value } = props;

    const active = value && !(value[0] === min && value[1] === max);

    // React.MouseEventHandler<HTMLDivElement>
    // const handleClick = (event: MouseEvent) => {
    //     if (event.currentTarget) {
    //         setAnchorEl(event.currentTarget);
    //     }
    // };
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent) => {
        setAnchorEl(undefined);
    };

    const handleChange = (value: number[]) => {
        onChange({ lower: value[0], upper: value[1] });
        // setTimeout(() => {
        //     onChange({ lower: value[0], upper: value[1] })
        // }, 100);
    }

    const handleClear = (event: React.MouseEvent) => {
        event.stopPropagation(); // prevent event from bubbling up to a click on the entire chip
        (event.target as HTMLElement).classList.add('active');

        setTimeout(() => {
            onChange({ lower: min, upper: max });
            (event.target as HTMLElement).classList.remove('active');
        }, 600); // match css transition timing
    }

    return (
        <div className="ChipSelect">
            {/* aria-describedby={id} */}
            <div className={active ? "Chip active" : "Chip"} onClick={handleClick}>
                <div className={active ? "ChipLabel active" : "ChipLabel"}>
                    {label}
                </div>

                {active ? <CloseIcon className='chip-select-icon' onClick={handleClear} sx={{ fontSize: "1rem" }} /> : <KeyboardArrowDownIcon className="chip-select-icon" sx={{ fontSize: "1rem" }} />}
            </div>
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div className="p-250">

                    <Stack sx={{ width: 300 }} spacing={2} direction="row" alignItems="center">
                        <div>{minLabel ? minLabel : min}</div>
                        <Slider
                            getAriaLabel={() => 'TikTok Followers'}
                            value={value}
                            onChange={(event, value) => handleChange(value as number[])}
                            valueLabelDisplay="auto"
                            step={step}
                            // marks
                            min={min}
                            max={max}
                            valueLabelFormat={value => value.toLocaleString()}
                        />
                        <div>{maxLabel ? maxLabel : max}</div>
                    </Stack>

                </div>
            </Popover>
        </div>
    );

}

export default ChipRange;