import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import './ChipRadioGroup.scss';
import CheckIcon from '@mui/icons-material/Check';
import { useRef } from 'react';

interface ChipRadioGroupProps {
    onChange: (newFilter: string | undefined) => unknown,
    // options: string[] | number[],
    options: {
        label: string | React.ReactElement,
        value: unknown,
    }[],
    value?: unknown
}

function ChipRadioGroup(props: ChipRadioGroupProps) {
    const ref = useRef<HTMLElement>(null);

    const { onChange, options, value } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string | undefined) => {
        if (ref?.current?.children) {
            for (const child of Array.from(ref.current.children)) {
                child.classList.remove('checked');
            }
        }

        if (event.target?.checked) {
            (event.target.parentNode?.parentNode as HTMLElement).classList.add('checked');
        }
        // else {
        //     (event.target.parentNode?.parentNode as HTMLElement).classList.remove('checked');
        // }

        onChange(value);
    }

    return (
        <RadioGroup ref={ref} className="ChipRadioGroup" value={value} defaultValue={options[0].value} onChange={handleChange}>
            {options?.map((option, i) => (
                <FormControlLabel key={`${option.value}-${i}`} className={value === option.value ? "checked" : ""} value={option.value} control={<Radio checkedIcon={<CheckIcon color="inherit" sx={{ marginRight: "0.5rem" }} />} icon={<></>} />} label={option.label} />
            ))}
        </RadioGroup>
    );
}

export default ChipRadioGroup;