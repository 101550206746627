import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions<any>();

interface AutocompleteProps {
    options: OptionType[],
    placeholder?: string,
    addNewLabel?: string,
    onChange: (newVal: OptionType | null) => unknown,
    value: OptionType | null,
    isErrored: boolean,
}

interface OptionType {
    inputValue?: string;
    label: string;
    id?: string;
}

function CampaignSelect(props: AutocompleteProps) {
    return (<>
        <Autocomplete
            sx={{
                '& .MuiOutlinedInput-root': {
                    padding: '0.375rem 0.75rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    '& .MuiAutocomplete-input': {
                        padding: 'unset',
                    },
                    backgroundColor: '#fff'
                },
                '& .MuiFormControl-root': {
                    margin: 0,
                },
                '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                    border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                    }
                },
                '& .MuiOutlinedInput-input': {
                    border: 'none !important'
                }
            }}
            value={props.value}
            options={props.options}
            onChange={(event, newValue) => {
                let parsedValue;
                if (typeof newValue === 'string') {
                    // parsedValue = {
                    //     label: newValue,
                    // };
                } else if (newValue && newValue.inputValue) {
                    parsedValue = {
                        label: newValue.inputValue
                    };
                    props.onChange(parsedValue);
                } else {
                    parsedValue = newValue;
                    props.onChange(parsedValue);
                }


            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add New Campaign: "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically...
                else if (option.inputValue) {
                    return option.inputValue;
                }
                // Option select from pre-existing list... 
                else {
                    return option.label;
                }
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    inputProps={{
                        ...params.inputProps,
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                />
            )}








        />
    </>);
}

export default CampaignSelect;