import React, { useState, useEffect } from 'react';
import { useFirebaseAuth } from '../services/firebase';
import LayoutOnboarding from '../components/LayoutOnboarding';
import './SignIn.scss';
import * as Sentry from "@sentry/react";

const SignIn: React.FC<any> = (props) => {
    const { signIn, signUp, resetPassword } = useFirebaseAuth();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [signupMode, setSignupMode] = useState(false);

    const [msg, setMsg] = useState<string>('');

    useEffect(() => {
        setMsg('');
    }, [signupMode])

    async function doSignIn(e: any) {
        e.preventDefault();
        if (email.length && password.length) {
            let res: any = await signIn(email, password);
            // console.log('signIn res', res);

            if (res.code && res.code === "auth/user-not-found") {
                setMsg("This user doesn't exist. Check that you entered the correct email or create a new account.")
            } else if (res.code && res.code === "auth/wrong-password") {
                setMsg("The password you entered is invalid. Please try again.");
            } else if (res.code) {
                setMsg(`An error occured: ${res.code}`);
                Sentry.captureMessage(`Login Error for Email ${email}`);
                Sentry.captureException(res);
            }
        } else {
            setMsg("Please enter a valid email and password.");
        }



    }

    async function doSignUp(e: any) {
        e.preventDefault();
        if (email.length && password.length) {
            let res: any = await signUp(email, password);

            if (res.code && res.code === "auth/email-already-in-use") {
                setMsg("A user with this email address already exists. Try logging in.");
            } else if (res.code) {
                setMsg("An error occured");
                console.log("Error", res);
            }
        } else {
            setMsg("Please enter a valid email and password.");
        }
    }

    async function doReset(e: any) {
        e.preventDefault();
        if (email.length) {
            let res: any = await resetPassword(email);
            console.log('doReset res', res);

            if (typeof res === 'undefined') {
                setMsg("Check your email for a reset link.");
            } else if (res.code === "auth/user-not-found") {
                setMsg("This user doesn't exist. Check your email or create a new account.")
            } else {
                setMsg("An error occured");
            }
        } else {
            setMsg("Please enter a valid email.");
        }
    }

    return (
        <>
            <div className="SignIn">
                <LayoutOnboarding>
                    <form className="card mb-100" onSubmit={(e) => signupMode ? doSignUp(e) : doSignIn(e)}>
                        <h2 className="mb-100">{signupMode ? "Sign Up" : "Log In"}</h2>
                        {msg ? <div className="mb-100 font-red">{msg}</div> : null}
                        <input placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} required />
                        <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                        <input className="btn btn-primary btn-block" type="submit" value={signupMode ? "Sign Up" : "Log In"} />
                        {signupMode ? null : <span className="change-mode-link mt-50" onClick={doReset}>Forgot Password?</span>}
                    </form>

                    <div>
                        {signupMode ?
                            <div>
                                Already a member? <span className="change-mode-link" onClick={() => setSignupMode(!signupMode)}>Log In</span>
                            </div>

                            : <div>
                                Don't Have an Account? <span className="change-mode-link" onClick={() => setSignupMode(!signupMode)}>Sign Up</span>
                            </div>
                        }
                    </div>
                </LayoutOnboarding>
            </div>


        </>
    );
}

export default SignIn;