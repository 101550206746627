import React, { useState, useEffect } from "react";

import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './ChipSelect.scss';

interface ChipSelectProps {
    active?: boolean, // maybe a duplicate of value?
    label: React.ReactNode,
    names?: string[] | React.ReactElement[],
    namesPrefix?: string,
    onChange: (newFilter: string[] | string) => unknown,
    options: string[],
    value: string[] | string,
    singleSelect?: boolean,
}


function ChipSelect(props: ChipSelectProps) {
    const [anchorEl, setAnchorEl] = useState<Element>();
    const [state, setState] = useState<Record<string, boolean>>({});
    const { active, label, names, namesPrefix, onChange, options, singleSelect, value } = props;

    useEffect(() => {
        setState(options.reduce((a, v) => ({ ...a, [v]: value.indexOf(v) > -1 }), {}));
    }, [options, value]);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    const handleRemoveAll = (event: React.MouseEvent) => {
        event.stopPropagation(); // prevent event from bubbling up to a click on the entire chip
        (event.target as HTMLElement).classList.add('active');

        setTimeout(() => {
            onChange([]);
            (event.target as HTMLElement).classList.remove('active');
        }, 600); // match css transition timing
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newState = state;

        if (singleSelect) {
            for (const key of Object.keys(state)) {
                newState[key] = false;
            }
        }

        newState[event.target.name] = event.target.checked;
        setState(newState);

        if (singleSelect) {
            // const currentVal = Object.entries(newState).find((obj) => obj[1] === true);
            // if (currentVal) {
            //     onChange(currentVal[0]);
            // }
            onChange(event.target.name);
        } else {
            onChange(Object.entries(newState).filter((entry) => entry[1] === true).map(entry => entry[0]));
        }

    };

    return (
        <div className="ChipSelect">
            {/* aria-describedby={id} */}
            <div className={active ? "Chip active" : "Chip"} onClick={handleClick}>
                <div className={active ? "ChipLabel active" : "ChipLabel"}>
                    {/* {console.log(`label`, label)} */}
                    {label}
                </div>

                {active && !singleSelect ? <CloseIcon className="chip-select-icon" onClick={handleRemoveAll} sx={{ fontSize: "1rem" }} /> : <KeyboardArrowDownIcon className="chip-select-icon" sx={{ fontSize: "1rem" }} />}
            </div>
            <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div className="p-100">
                    {namesPrefix ? <div className="font-12 font-gray-7 mb-50 text-uppercase">{namesPrefix}</div> : null}
                    {singleSelect ?
                        <RadioGroup
                            onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
                        >
                            {options.map((option, i) => (
                                <FormControlLabel sx={{ display: 'flex', 'MuiFormControlLabel-label': { display: 'flex ' } }} key={option} name={option} checked={state[option] || false}
                                    control={
                                        <Radio />
                                    }
                                    label={names ? names[i] : option}
                                />
                            ))}
                        </RadioGroup>
                        : options.map((option, i) => (
                            <FormControlLabel sx={{ display: 'flex' }} key={option} name={option} checked={state[option] || false}
                                control={
                                    <Checkbox />
                                }
                                onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
                                label={names ? names[i] : option}
                            />
                        ))}
                </div>
            </Popover>
        </div>
    );
}

export default ChipSelect;