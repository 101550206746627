import React, { useEffect } from 'react';
import { useFirebaseAuth, UserContext } from "./services/firebase";
import { analyticsService } from './services/analytics';
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom"; // Switch, Redirect, useLocation
import PageAnalytics from './components/PageAnalytics';

import * as Sentry from "@sentry/react";
import './theme/index.scss';

// PAGES
import NewBooking from './pages/NewBooking';
import Bookings from './pages/Bookings';
import SignIn from "./pages/SignIn";
import NewUserInfo from "./pages/NewUserInfo";
import EditClient from './pages/EditClient';
import EditUser from './pages/EditUser';
import EditTalent from './pages/EditTalent';
import Roster from './pages/Roster';
import Managers from './pages/Managers';
import Test from './pages/Test';
import Lists from './pages/Lists';
import EditList from './pages/EditList';

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Campaigns from './pages/Campaigns';
import { LicenseInfo } from '@mui/x-license-pro';
import CampaignDetailsPage from './pages/CampaignDetails';
import { RecommendTest } from './pages/RecommendTest';


LicenseInfo.setLicenseKey('076c39cc3ea1088a440aa328bb5e1b2cTz05NzY0NyxFPTE3NTcyNTM1MjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

if (process.env.NODE_ENV === 'development') {
    console.log("Development; won't init Sentry");
} else {
    Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: "https://d0dd3e04e48945d283ea7773835a8daa@o4504368797908992.ingest.sentry.io/4504368801185792",
        // integrations: [new BrowserTracing()],
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false, maskAllInputs: false })],
        release: "grail-app@" + process.env.REACT_APP_VERSION,

        // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#using--1
        ignoreErrors: ["IDBDatabase", "Network Error", "network error", "timed out", "client is offline"],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#62edff',
            contrastText: '#000000'
        },
        success: {
            main: '#7ACCAE',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#62edff',
            contrastText: '#000000',
            300: '#E2FCFF',
        },
        secondary: {
            main: '#7AB5CC',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#FF6D49',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#E02B5F',
            contrastText: '#FFFFFF'
        }
    },
    components: {
        // MuiChip: {
        //     variants: [
        //         {
        //             props: {},
        //             style: {
        //                 "color": "white",
        //                 "font-weight": "500"
        //             }
        //         }
        //     ]
        // },
        MuiFormControl: {
            styleOverrides: {
                // root: {
                //     marginTop: "0.5rem",
                //     marginBottom: "0.5rem"
                // }
            }
        }
    }
});

// theme!.components!.MuiChip!.variants = [
//     {
//         props: { color: "success" },
//         style: {
//             backgroundColor: alpha(theme.palette.success.main, 0.12),
//             color: theme.palette.success.main,
//             fontWeight: 600,
//             '&:hover': {
//                 backgroundColor: alpha(theme.palette.success.main, 0.24),
//             }
//         }
//     },
//     {
//         props: { color: "warning" },
//         style: {
//             backgroundColor: alpha(theme.palette.warning.main, 0.12),
//             color: theme.palette.warning.main,
//             fontWeight: 600,
//             '&:hover': {
//                 backgroundColor: alpha(theme.palette.warning.main, 0.24),
//             }
//         }
//     },
//     {
//         props: { color: "secondary" },
//         style: {
//             backgroundColor: alpha(theme.palette.secondary.main, 0.12),
//             color: theme.palette.secondary.main,
//             fontWeight: 600,
//             '&:hover': {
//                 backgroundColor: alpha(theme.palette.secondary.main, 0.24),
//             }
//         }
//     }
// ];

// const persister = createSyncStoragePersister({
//     storage: window.localStorage,
//     // retry: removeOldestQuery
// });

const App: React.FC<any> = () => {
    const { userAuthed, userLoading, user } = useFirebaseAuth();
    // const location = useLocation(); // <-- get current location being accessed

    useEffect(() => {
        if (user?.id) {
            analyticsService().setUserId(user.id);
        }
    }, [user]);

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <UserContext.Provider value={user}>
                    <BrowserRouter>
                        {/* <PageAnalytics /> */}


                        {userLoading ? ( // NEEDS TO BE FIRST, or we'll get redirects before we've loaded (e.g. if you navigate anywhere besides '/' )
                            <Routes>
                                <Route path="/" element={<div className="AppLoading"><CircularProgress sx={{ color: '#62edff' }} /></div>} />
                            </Routes>
                        ) : (
                            <>
                                {userAuthed?.uid ? (
                                    // PROTECTED ROUTES
                                    <>
                                        {user?.email ? (
                                            <>
                                                {
                                                    ((user.campaignType && user.firstName && user.lastName && (user.company || user.clientId)) || user.roles?.manager || user.roles?.externalManager || user.roles?.admin) ? (
                                                        <>
                                                            <PageAnalytics />
                                                            <Routes>
                                                                <Route path="/">
                                                                    <Route index element={user.roles?.manager || user?.roles?.admin || user?.roles?.externalManager ? <Bookings /> : <Navigate to="/new-booking" />} />
                                                                    <Route path="new-booking">
                                                                        <Route path="/new-booking" element={<NewBooking />} />
                                                                        <Route path="/new-booking/:listId" element={<NewBooking />} />
                                                                    </Route>
                                                                    <Route path="/new-user" element={<NewUserInfo />} />
                                                                    <Route path="/edit-user/:uid" element={<EditUser />} />
                                                                    <Route path="/edit-talent/:talentId" element={<EditTalent />} />
                                                                    <Route path="/edit-client/:clientId" element={<EditClient managerMode={true} adminMode={user?.roles?.admin} />} />
                                                                    <Route path="/campaign/:campaignId" element={<CampaignDetailsPage />} />
                                                                    <Route path="roster">
                                                                        <Route path="/roster/" element={<Roster />} />
                                                                        <Route path="/roster/:uid" element={<Roster />} />
                                                                    </Route>
                                                                    <Route path="/campaigns/">
                                                                        <Route path="/campaigns/" element={<Campaigns />} />
                                                                        <Route path="/campaigns/:uid" element={<Campaigns />} />
                                                                    </Route>
                                                                    {/* <Route path="/lists/:uid" element={<Lists />} /> */}

                                                                    <Route path="/lists/">
                                                                        <Route path="/lists/creator-requests" element={<Lists />} />
                                                                        <Route path="/lists/:uid" element={<Lists />} />
                                                                    </Route>

                                                                    <Route path="/edit-list/:listId" element={<EditList />} />
                                                                    {
                                                                        (user.roles?.admin ? (
                                                                            <>
                                                                                <Route path="/managers" element={<Managers />} />
                                                                                <Route path="recommend-test" element={<RecommendTest />} />
                                                                            </>
                                                                        ) : null)
                                                                    }
                                                                    <Route path="/test" element={<Test />} />
                                                                    <Route path=":wildcard/*" element={<Navigate to="/" />} />
                                                                    <Route path="/new-client/:clientId" element={<EditClient />} />
                                                                </Route>
                                                            </Routes>
                                                        </>
                                                    ) : (
                                                        // If a user is only partially registered...
                                                        // Show NewUserInfo, then redirect to previous path
                                                        <Routes>
                                                            <Route path="*" element={<>
                                                                <PageAnalytics pageName="new-user" />
                                                                <NewUserInfo />
                                                            </>} />
                                                        </Routes>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            // Keep loading until we've actually got the full user object...
                                            <Routes>
                                                <Route path="/" element={<div className="AppLoading"><CircularProgress sx={{ color: '#62edff' }} /></div>} />
                                            </Routes>
                                        )}

                                    </>
                                ) : (
                                    // PUBLIC ROUTES
                                    <Routes>
                                        <Route path="/new-client/:clientId" element={<>
                                            <PageAnalytics />
                                            <EditClient />
                                        </>} />

                                        {/* Show SignIn, then redirect on sign-in/up to previous path  */}
                                        <Route path="*" element={<>
                                            <PageAnalytics pageName="sign-in" />
                                            <SignIn />
                                        </>} />
                                    </Routes>
                                )}
                            </>
                        )}
                    </BrowserRouter>
                </UserContext.Provider>
            </ThemeProvider>
        </div >
    );
}

export default App;