import grailUtil from "../services/grailUtil";
import { Talent } from '../shared/types';
import RenderIfVisible from '../components/RenderIfVisible';
import TalentMini from "./TalentMini";

import './BookingSummary.scss';

interface BookingSummaryProps {
    talent: Talent[],
    // selectedTalent: string[],
    selectedTalent: { [key: string]: number },
    userCurrency: string,
    totalPrice: number,
    totalTiktokFollowers: number,
    totalInstagramFollowers: number,
    handleRemove: (id: string) => any,
    recsLength?: number,
    onRecsClick?: () => unknown
}

function BookingSummary(props: BookingSummaryProps) {
    const { talent, selectedTalent, userCurrency, totalPrice, totalTiktokFollowers, totalInstagramFollowers, handleRemove, recsLength, onRecsClick: handleRecsClick } = props;

    return (
        <div className="BookingSummary">

            <div className="BookingSummaryStats">
                <div className="">
                    <div className="stats">
                        <div className="mb-50">
                            <div>{selectedTalent?.length}</div>
                            <div className="label">Influencers Selected</div>
                        </div>
                        <div>
                            <div>{grailUtil.getCurrencySymbol(userCurrency) + totalPrice.toLocaleString()}</div>
                            <div className="label">Estimated Price</div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="stats">
                        <div className="statsItem">
                            <div className="icon-tiktok icon-black icon-32 mr-50"></div>
                            <div>
                                <div>{grailUtil.abbreviateNumber(totalTiktokFollowers)}</div>
                                <div className="label">Followers</div>
                            </div>
                        </div>
                        <div className="statsItem">
                            <div className="icon-instagram icon-red icon-32 mr-50"></div>
                            <div>
                                <div>{grailUtil.abbreviateNumber(totalInstagramFollowers)}</div>
                                <div className="label">Followers</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr />

            <div className="BookingSummaryList">
                {talent.filter((tal: Talent) => selectedTalent[tal.id]).map((tal: Talent) => (
                    <RenderIfVisible defaultHeight={32} key={tal.id}>
                        <div key={tal.id}>
                            <TalentMini handleRemove={handleRemove} isChecked={selectedTalent[tal.id]} tal={tal} currency={userCurrency} price={selectedTalent[tal.id]} />
                        </div>
                    </RenderIfVisible>
                ))}
            </div>

            {
                recsLength ? (
                    <div className="text-center my-200"><button className="btn btn-link btn-link-red" onClick={handleRecsClick}>View {recsLength} Recommended Creators</button></div>
                ) : null
            }
        </div>
    );
}

export default BookingSummary;