import React, { useState, useRef } from 'react';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import ModalStyle from '../../theme/mui-styles/modal';

import { Booking, User, Client, Campaign, Talent } from '../../shared/types';

// const options = ['confirm', 'decline', 'approve', 'cancel'] as const;

interface BookingActionButtonProps {
    booking: Booking,
    booker?: User,
    campaign?: Campaign,
    client?: Client,
    talent?: Talent,
    updateDocField: any,
    updateDoc: any,
    disabled?: boolean,
}

function BookingActionButton(props: BookingActionButtonProps) {
    const { booking, booker, campaign, client, updateDocField, updateDoc, disabled, talent } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [declineModalOpen, setDeclineModalOpen] = useState(false);
    const [discountConfirmChecked, setDiscountConfirmChecked] = useState(false);
    const [adminCcChecked, setAdminCcChecked] = useState(false);
    const [declineReason, setDeclineReason] = useState<"" | Booking["declineReason"]>("");
    const anchorRef = useRef<HTMLDivElement>(null);

    const bookerId = booking.bookerId || booker?.id || campaign?.booker?.uid || null;
    const clientId = booking.clientId || booker?.clientId || null;
    const airtableBookerId = booking.airtableBookerId || booker?.airtableBookerId || null;
    const airtableClientId = booking.airtableClientId || client?.airtableId || booker?.airtableClientId || null;
    const airtableCampaignId = booking.airtableCampaignId || campaign?.airtableId || null;

    const handleMenuToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
        syncIds();
    };

    const handleMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setMenuOpen(false);
    };

    // STATUS LOGIC
    const statusIsNew = !booking.status || booking.status === null; // || booking.status === "new"
    // const bookerValid = booker?.firstName && booker?.lastName && booker?.email;
    // const clientValid = client?.name && client.address?.addressLine1 && client?.address?.city && client?.address?.state && client?.address?.postCode && client?.address?.country && client?.billingEmail;
    // const enoughInfoToApprove = bookerValid && clientValid && booking.airtableClientId && booking.currency && booking.price && (client?.requiresPurchaseOrder ? booking.purchaseOrder : true) && (client?.requiresUniportEmail ? booking.uniportEmail : true);
    // let missingInfo = `${!bookerValid ? 'Booker Validity, ' : ""}${!clientValid ? 'Client Validity, ' : ""}${!booking.airtableClientId ? "airtableClientId, " : ""}${!booking.currency ? "Currency, " : ""}${!booking.price ? "Price, " : ""}${client?.requiresPurchaseOrder && !booking.purchaseOrder ? "PO Number, " : ""}${client?.requiresUniportEmail && !booking.uniportEmail ? "Uniport Email, " : ""}`;
    // missingInfo = missingInfo.substring(0, missingInfo.lastIndexOf(", "));
    // if (booking.bookingType !== 'downpayment' && booking.bookingType !== 'event' && !booking.videoLink) {
    //     missingInfo += "Video Link, ";
    // }

    const approvalBlocks = [];
    if (!booker?.firstName || !booker?.lastName || !booker?.email) {
        approvalBlocks.push("Missing Booker Info");
    }

    if (booker && !booker.clientId) {
        approvalBlocks.push("Booker missing client; select client from Booker Edit screen.");
    } else {
        if (!booking.airtableId && (booking?.docMeta?.createTime && dayjs().diff(booking.docMeta.createTime.toDate(), 'minute') < 18)) {
            approvalBlocks.push("Still waiting on Record ID from Airtable; check back in a few minutes...");
        }
        if (!client?.companySector) {
            approvalBlocks.push("Missing Client Info - Company Sector");
        }
        if (!client?.name) {
            approvalBlocks.push("Missing Client Info - Name");
        }
        if (!client?.address?.addressLine1) {
            approvalBlocks.push("Missing Client Info - Address Line 1");
        }
        if (!client?.address?.city) {
            approvalBlocks.push("Missing Client Info - City");
        }
        if (!client?.address?.state) {
            approvalBlocks.push("Missing Client Info - State/Region");
        }
        if (!client?.address?.postCode) {
            approvalBlocks.push("Missing Client Info - Zip/Postal Code");
        }
        if (!client?.address?.country) {
            approvalBlocks.push("Missing Client Info - Country");
        }
        if ((client?.address?.country === 'UK' || client?.address?.country === 'United Kingdom') && !client.address?.postCode?.trim().match(/^.*[a-zA-Z].*$/)) {
            approvalBlocks.push(`Client Country and Zip Code Mismatch - It looks like the client's country is set to the UK but a US zip code (${client.address?.postCode}) was used; contact Lyonel to fix this issue.`);
        }
        if ((client?.address?.country === 'US' || client?.address?.country === 'United States') && client.address?.postCode?.trim().match(/^.*[a-zA-Z].*$/)) {
            approvalBlocks.push(`Client Country and Zip Code Mismatch - It looks like the client's country is set to the US but a UK post code (${client.address?.postCode}) was used; contact Lyonel to fix this issue.`);
        }
        if (!client?.billingEmail) {
            approvalBlocks.push("Missing Client Info - Billing Email");
        }
        if (!booking.airtableClientId) {
            approvalBlocks.push("Missing Airtable Client ID");
        }
        if (talent?.category?.toLowerCase() === 'micro-influencer' && !booking.overrideMicroRevenue) {
            approvalBlocks.push("Creator is a Micro Creator and No Commission Override is Present");
        }
    }

    // if (!(client?.name && client.address?.addressLine1 && client?.address?.city && client?.address?.state && client?.address?.postCode && client?.address?.country && client?.billingEmail)) {
    //     approvalBlocks.push("Missing Client Info");
    // }

    if (!booking.bookingType && !booking.videoLink) {
        approvalBlocks.push("Missing Video Link");
    }
    if (campaign?.campaignType === 'Brand' && !booking.bookingType && !booking.contractFile) {
        approvalBlocks.push("Missing Brand Contract");
    }
    if (booking.status === 'cancelled' || booking.status === 'declined') {
        approvalBlocks.push("Booking Cancelled or Declined");
    }
    if (booking.status === 'approved') {
        approvalBlocks.push("Booking Already Approved");
    }
    if (!booking.currency || !booking.price) {
        approvalBlocks.push("Missing Currency or Price");
    }
    if (client?.requiresPurchaseOrder && !booking.purchaseOrder) {
        approvalBlocks.push("Client Requires PO Number");
    }
    if (client?.requiresUniportEmail && !booking.uniportEmail) {
        approvalBlocks.push("Client Requires Uniport Email");
    }
    if (client?.requiresInvoiceNotes && !booking.invoiceNotes) {
        approvalBlocks.push("Client Requires Invoice Notes");
    }

    // `${!booking.airtableClientId ? "airtableClientId, " : ""}`;

    // Function to sync any missing IDs...
    function syncIds() {
        const bookingUpdates: Booking = {
            id: booking.id,
        };

        if (!booking.bookerId && bookerId) {
            bookingUpdates.bookerId = bookerId;
        }
        if (!booking.clientId && clientId) {
            bookingUpdates.clientId = clientId;
        }
        if (!booking.airtableBookerId && airtableBookerId) {
            bookingUpdates.airtableBookerId = airtableBookerId;
        }
        if (!booking.airtableClientId && airtableClientId) {
            bookingUpdates.airtableClientId = airtableClientId;
        }
        if (!booking.airtableCampaignId && airtableCampaignId) {
            bookingUpdates.airtableCampaignId = airtableCampaignId;
        }
        if (Object.keys(bookingUpdates).length > 1) {
            console.log(`syncingIds for booking`, bookingUpdates);
            updateDoc("bookings", booking.id, bookingUpdates, "syncIds");
        }


        if (booker?.id && !booker?.clientId && clientId) {
            updateDocField("users", booker.id, "clientId", clientId);
            // console.log("5 - updating booker: clientId");
        }
        if (booker?.id && !booker?.airtableClientId && airtableClientId) {
            updateDocField("users", booker.id, "airtableClientId", airtableClientId);
            // console.log("6 - updating booker: airtableClientId");
        }
    }

    function handleCancel() {
        updateDocField("bookings", booking?.id, "status", "cancelled");
        setCancelModalOpen(false);
    }

    function handleApprove() {
        // updateDocField("bookings", booking?.id, "status", "approved");
        // updateDocField("bookings", booking?.id, "approvalDate", new Date());

        updateDoc("bookings", booking?.id, { status: "approved", approvalDate: new Date() }, "status approved");
        setApproveModalOpen(false);
    }

    function handleDecline(declineReason: Booking["declineReason"]) {
        updateDoc("bookings", booking?.id, { status: "declined", declineReason: declineReason }, "status declined");
        setDeclineReason("");
        setDeclineModalOpen(false);
    }


    return (
        <>
            {
                booking.id ?
                    <div ref={anchorRef}>
                        {/* <Button onClick={handleClick}>{options[selectedIndex]}</Button> */}
                        {/* <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{selectedOption}</Button> */}
                        <Tooltip title={disabled ? `Only admins and managers of this TikTokker can edit this record.` : ""}>
                            <div>
                                <IconButton
                                    // variant="contained"
                                    size="small"
                                    aria-controls={menuOpen ? 'split-button-menu' : undefined}
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleMenuToggle}
                                    disabled={disabled}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                        {/* </ButtonGroup> */}
                        <Popper
                            sx={{
                                zIndex: 100,
                            }}
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleMenuClose}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                <MenuItem
                                                    // onClick={(event) => handleMenuItemClick(event, "confirm")}
                                                    disabled={!statusIsNew}
                                                    onClick={(event) => updateDocField("bookings", booking?.id, "status", "confirmed")}
                                                >
                                                    Confirm
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={!statusIsNew && booking.status !== 'confirmed'}
                                                    // onClick={(event) => updateDocField("bookings", booking?.id, "status", "declined")}
                                                    onClick={(event) => setDeclineModalOpen(true)}
                                                >
                                                    Decline
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={booking.status !== 'confirmed'}
                                                    onClick={(event) => updateDocField("bookings", booking?.id, "status", "draft")}
                                                >
                                                    Draft Created
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={statusIsNew || booking.status === 'cancelled'}
                                                    // onClick={(event) => updateDocField("bookings", booking?.id, "status", "cancelled")}
                                                    onClick={(event) => setCancelModalOpen(true)}
                                                >
                                                    Cancel
                                                </MenuItem>
                                                <div>
                                                    <MenuItem
                                                        disabled={booking.status === 'cancelled' || booking.status === 'approved' || booking.status === 'declined'}
                                                        onClick={() => setApproveModalOpen(true)}
                                                    >
                                                        <div>
                                                            <div>Approve</div>
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <Modal open={approveModalOpen} onClose={() => setApproveModalOpen(false)}>
                            <Box sx={ModalStyle}>
                                {approvalBlocks.length ? (<>
                                    <div className="font-weight-bold font-24 mb-100">Can't Approve Booking</div>
                                    <ul>
                                        {approvalBlocks.map((item) => <li key={item}>{item}</li>)}
                                    </ul>
                                </>) : (<>
                                    <div className="font-weight-bold font-24 mb-100">Ready to Approve?</div>
                                    <div className="mb-100">Are you sure you want to approve <em>{campaign?.campaignName}</em> by <em>@{talent?.tiktokUser || talent?.instagramUser || booking.talentName}</em>?</div>
                                    <div className="mb-100">This will generate an invoice and <strong>cannot be undone</strong>.</div>
                                    {client?.discountPercentage ? <div className="mb-100 font-12">
                                        <div><strong>Client Discount Confirmation</strong></div>
                                        <input type="checkbox" checked={discountConfirmChecked} onChange={() => setDiscountConfirmChecked(!discountConfirmChecked)} />
                                        I confirm that I have logged the booking price for this deal as the "post-discount" rate (i.e taking into account the 5% discount agreed on Creed deals), and that this is the rate that has been communicated to my creator.
                                    </div> : null}
                                    {client?.requiresAdminCC ? <div className="mb-100 font-12">
                                        <div><strong>CC Lyonel Confirmation</strong></div>
                                        <input type="checkbox" checked={adminCcChecked} onChange={() => setAdminCcChecked(!adminCcChecked)} />
                                        I confirm I have signed the PDF invoice template sent by the booker and sent it back to them. <strong>Without this step payment will not be remitted!</strong>
                                    </div> : null}
                                    <div className="flex">
                                        <div className="flex-1-0-auto mr-100"><button className="btn btn-block btn-outline-red btn-sm" onClick={() => setApproveModalOpen(false)}>Nope, Not Ready</button></div>
                                        <div className="flex-1-0-auto"><button disabled={(!!client?.discountPercentage && discountConfirmChecked === false) || (!!client?.requiresAdminCC && adminCcChecked === false)} className="btn btn-block btn-primary btn-sm" onClick={handleApprove}>Approve Booking</button></div>
                                    </div>
                                </>)}
                            </Box>
                        </Modal>

                        <Modal open={cancelModalOpen} onClose={() => setCancelModalOpen(false)}>
                            <Box sx={ModalStyle}>
                                <div className="font-weight-bold font-24 mb-100">Are You Sure You Want to Cancel?</div>
                                <div className="mb-100">Are you sure you want to cancel Booking for <em>{campaign?.campaignName}</em> by <em>@{talent?.tiktokUser || talent?.instagramUser || booking.talentName}</em>?</div>
                                <div className="mb-100 font-weight-bold">THIS CANNOT BE UNDONE.</div>
                                <div className="flex">
                                    <div className="mr-100 flex-1-0-auto"><button className="btn btn-block btn-outline-red btn-sm" onClick={handleCancel}>Cancel Booking</button></div>
                                    <div className="flex-1-0-auto"><button className="btn btn-block btn-primary btn-sm" onClick={() => setCancelModalOpen(false)}>Back to Safety</button></div>
                                </div>
                            </Box>
                        </Modal>

                        <Modal open={declineModalOpen} onClose={() => {
                            setDeclineModalOpen(false);
                            setDeclineReason("");
                        }}>
                            <Box sx={ModalStyle}>
                                <div className="font-weight-bold font-24 mb-100">Why are you declining this record?</div>
                                <div className="mb-100">Why are you declining this booking for <em>{campaign?.campaignName}</em> by <em>@{talent?.tiktokUser || talent?.instagramUser || booking.talentName}</em>?</div>
                                {/* <div className="mb-100 font-weight-bold">THIS CANNOT BE UNDONE.</div> */}
                                <select className="mb-100" value={declineReason} onChange={(event) => setDeclineReason(event.target.value as Booking["declineReason"])}>
                                    <option value="" disabled>Select a Reason...</option>
                                    <option value="relog">Relogging this deal due to a data issue</option>
                                    <option value="booker_cancel">The booker cancelled</option>
                                    <option value="creator_decline">The creator declined</option>
                                </select>
                                <div className="flex">
                                    <div className="mr-100 flex-1-0-auto"><button className="btn btn-block btn-outline-red btn-sm" disabled={!declineReason} onClick={() => handleDecline(declineReason as Booking["declineReason"])}>Decline Booking</button></div>
                                    <div className="flex-1-0-auto"><button className="btn btn-block btn-primary btn-sm" onClick={() => setDeclineModalOpen(false)}>Back to Safety</button></div>
                                </div>
                            </Box>
                        </Modal>
                    </div> : null
            }
        </>
    );
}

export default BookingActionButton;