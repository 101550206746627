
import { AutocompleteOption, Client } from "../shared/types";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";

const clientFilter = createFilterOptions<AutocompleteOption<Client>>();

interface ClientSelectProps {
    value?: Client,
    onChange: (event: React.SyntheticEvent<Element, Event>, value: string | AutocompleteOption<Client> | null) => unknown,
    clientOptions: AutocompleteOption<Client>[],
    disabled?: boolean,
}

function ClientSelect(props: ClientSelectProps) {
    // const [clientOptions, setClientOptions] = useState<AutocompleteOption<Client>[]>([]);
    const { clientOptions, disabled, onChange, value } = props;

    return (
        <Autocomplete
            disabled={disabled}
            sx={{
                '& .MuiOutlinedInput-root': {
                    padding: '0.375rem 0.75rem',
                    height: 'calc(1.5em + 0.75rem + 2px)',
                    '& .MuiAutocomplete-input': {
                        padding: 'unset',
                    },
                    backgroundColor: '#fff'
                },
                '& .MuiFormControl-root': {
                    margin: 0,
                },
                '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                    // border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                    border: '1px solid #ced4da !important',
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        //     border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                        border: '1px solid #ced4da !important',
                    }
                },
                '& .MuiOutlinedInput-input': {
                    border: 'none !important'
                }
            }}
            id="client-select"
            value={value ? { val: value, label: value.name || value.id! } : null}
            // isOptionEqualToValue={(option, value) => option.val === value.val}
            freeSolo
            options={clientOptions}
            autoHighlight
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            onChange={onChange}
            renderOption={(props, option) => {
                const labelParts = option.label.split(' *** ');

                return (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.label.includes('Add New') ? (<>{option.label}</>) : (
                            <div>
                                <div>{labelParts[0]}</div>
                                <div style={{ fontSize: '10px' }}>Airtable ID: {labelParts[1]}</div>
                                <div style={{ fontSize: '10px' }}>Platform ID: {labelParts[2]}</div>
                            </div>
                        )}

                    </Box>

                )
            }}
            renderInput={(params) => (
                <TextField

                    {...params}
                    // label="Select a Client"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        form: {
                            autocomplete: 'off', // https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
                        },
                    }}
                    required
                />
            )}

            filterOptions={(options, params) => {
                const filtered = clientFilter(options, params);
                // const filtered = [];
                const { inputValue } = params;

                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        val: undefined,
                        inputValue: params.inputValue,
                        label: `Add New Client: "${inputValue}"`,
                    });
                }

                return filtered;
            }}
        />
    );
}

export default ClientSelect;