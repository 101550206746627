import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { User } from '../shared/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ManagerSelectProps {
    managers: User[],
    value: string[],
    onChange: any,
    disabled?: boolean
}

const sx = {
    '& .MuiOutlinedInput-root': {
        // border: '1px solid red !important',
        padding: '0.375rem 0.75rem',
        // padding: '0 !important',
        height: 'unset',
        minHeight: 'calc(1.5em + 0.75rem + 2px)',
        '& .MuiAutocomplete-input': {
            padding: 'unset',
        },
        backgroundColor: '#fff'
    },
    '& .MuiFormControl-root': {
        margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
        // border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
    },
    '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            // border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
        }
    },
    '& .MuiOutlinedInput-input': {
        border: 'none !important'
    },
    '& .MuiAutocomplete-tag': {
        maxWidth: 'calc(100% - 2rem)'
    }
};

export default function ManagerSelect(props: ManagerSelectProps) {
    const { managers, value, onChange, disabled } = props;

    return (
        <Autocomplete
            sx={sx}
            limitTags={1}
            size="small"
            multiple
            id="checkboxes-tags-demo"
            options={managers.map((manager) => manager.id)}
            value={value}
            disabled={disabled}
            disableCloseOnSelect
            // onChange={(e, v) => console.log(v)}
            onChange={onChange}
            getOptionLabel={(managerId) => managers.find((manager) => managerId === manager.id)?.email || ''}
            renderOption={(props, managerId, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {managers.find((manager) => managerId === manager.id)?.email || ''}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} />
            )}
        // renderInput={(params) => (
        //     <div ref={params.InputProps.ref}>
        //         <input type="text" {...params.inputProps} className="" value={value.map((managerId) => managers.find((manager) => manager.id === managerId)?.email || managerId).join(", ")} />
        //     </div>
        // )}
        />
    );
}