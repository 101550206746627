import React from 'react';
import './LayoutOnboarding.scss';

const LayoutOnboarding: React.FC<any> = (props) => {
    return (
        <>
            <section className="LayoutOnboarding">
                <img src="/logo.svg" alt="Grail Talent Logo" />

                <main>{props.children}</main>
            </section>
            <footer>
                <div className="font-10">v{process.env.REACT_APP_VERSION}</div>
                <div>&copy; Grail Talent</div>
                <div><a href="https://grail-talent.com/terms" target="_blank" rel="noreferrer">Terms</a></div>
            </footer>
        </>
    );
}

export default LayoutOnboarding;