import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// REACT QUERY
import {
  QueryClient,
  // QueryClientProvider,
} from '@tanstack/react-query';
import { PersistedClient, Persister } from "@tanstack/react-query-persist-client";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { get, set, del } from "idb-keyval";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Seems to work more consistently if it's above <App />
// per https://tanstack.com/query/v4/docs/react/plugins/persistQueryClient#usage-with-react
function createIDBPersister(idbValidKey: IDBValidKey = "reactQuery") {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
}

const persister = createIDBPersister();
const buster = '1709332222395'; // Manually replace when making changes that require cache-busting - use Date.now() to generate

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 1, // 1 minutes
      // refetchOnMount: 'always', // NOTE: this doesn't override disabled queries... when a query re-enables, it won't refetch unless it's stale
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    {/* <QueryClientProvider client={queryClient}> */}
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister, buster }}>
      <ReactQueryDevtools initialIsOpen={false} />
      <App />
    </PersistQueryClientProvider>
    {/* </QueryClientProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
