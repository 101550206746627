// import { useContext } from "react";
// import { UserContext, useBookings, useFreshBookers, useFreshTalent, useFreshCampaigns, useFreshClients, useFreshManagers } from "../services/firebase";
import Layout from "../components/Layout";

export default function Test() {
    // const bookersQuery = useFreshBookers();
    // const bookers = bookersQuery.flatData;

    // const campaignsQuery = useFreshCampaigns(true);
    // const campaigns = campaignsQuery.flatData;

    // const clientsQuery = useFreshClients();
    // const clients = clientsQuery.flatData;

    // const managersQuery = useFreshManagers();
    // const managers = managersQuery.flatData;

    // const talentQuery = useFreshTalent(true, true);
    // const talent = talentQuery.flatData;

    return <>
        <Layout>
            <div className="flex">
                For Testing...

                {/* {bookers?.length ?
                    <div>
                        <div><strong>Bookers</strong> - {bookers.length}</div>
                        <div>{bookers[0]?.firstName} - {bookers[0]?.lastName}</div>
                    </div>
                    : null} */}


                {/* {campaigns?.length ?
                    <div>
                        <div><strong>Campaigns</strong> - {campaigns.length}</div>
                        <div>{campaigns[0]?.id} - {campaigns[0]?.booker?.email}</div>
                    </div>
                    : null} */}

                {/* {clients?.length ?
                    <div>
                        <div><strong>Clients</strong> - {clients.length}</div>
                        <div>{clients[0]?.id} - {clients[0]?.name}</div>
                    </div>
                    : null} */}

                {/* {managers?.length ?
                    <div>
                        <div><strong>Clients</strong> - {managers.length}</div>
                        <div>{managers[0]?.id} - {managers[0]?.email}</div>
                    </div>
                    : null} */}

                {/* {talent?.length ?
                    <div>
                        <div><strong>Talent</strong> - {talent.length}</div>
                        {talent?.filter((tal) => tal.tiktokUser === 'grailtest1').map((tal) => <div key={tal.id}>{tal.name} - {tal.brandPrice}</div>)}
                    </div>
                    : null} */}
            </div>
        </Layout>
    </>
}