import React, { useContext } from 'react';
import './Layout.scss';
import { useFirebaseAuth, UserContext } from "../services/firebase";
import Drift from './Drift';
import { Link } from 'react-router-dom';

import PrimaryNavigation from './PrimaryNavigation';

const Layout: React.FC<any> = (props) => {
    const { signOut } = useFirebaseAuth();
    const user = useContext(UserContext);

    return (
        <section className={props.gray ? "Layout LayoutGray" : "Layout"}>
            <nav>
                <Link to="/">
                    <img src="/logo.svg" alt="Grail Talent" />
                    {/* <span className="logo-font">Grail Talent</span> */}
                </Link>
                {/* <div className="btn btn-primary btn-sm" onClick={signOut}>Log Out</div> */}
                <PrimaryNavigation signOut={signOut} user={user} />
            </nav>

            <main>{props.children}</main>

            {
                user?.id && !user?.roles?.manager && !user.roles?.admin ?
                    <Drift appId="gdkdrsrydzrd"
                        userId={user.id}
                        attributes={{ email: user.email, company: user.company, phone_number: user.phone, first_name: user.firstName, last_name: user.lastName }}
                    /> : null
            }

            <footer>
                <div className="font-10">v{process.env.REACT_APP_VERSION}</div>
                <div>&copy; Grail Talent</div>
                <div><a href="https://grail-talent.com/terms" target="_blank" rel="noreferrer">Terms</a></div>
            </footer>
        </section>
    );
}

export default Layout;