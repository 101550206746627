import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import { useQueryClient } from "@tanstack/react-query";

export default function DataLoading() {
    const queryClient = useQueryClient();
    const cache = queryClient.getQueryCache();
    const queries = cache.findAll();
    const fetchingList = queries.filter((query) => query.state.fetchStatus === 'fetching').map((query) => query.queryKey[0]);

    return <>
        {fetchingList.length ?
            <div className="flex align-items-center justify-content-center">
                <Tooltip title={`Refreshing ${fetchingList?.join(', ')}...`}>
                    <CircularProgress size="1.5rem" />
                </Tooltip>
            </div> : null
        }
    </>;
}