
// import { useEffect, useState } from 'react';
import { updateUserRole, useManagers2 } from '../services/firebase';
// import { User } from '../services/types';
import grailUtil from '../services/grailUtil';

// Components
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium'; // GridCellModes // GridCellEditCommitParams, MuiEvent,
import Layout from "../components/Layout";

// Icons
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';

export default function Managers() {
    const apiRef = useGridApiRef();
    // const managers = useManagers(apiRef);
    const managers = useManagers2(apiRef);

    const columns: GridColDef[] | any[] = [
        { field: 'id', headerName: 'Grail Platform ID', width: 150 },
        { field: 'managerAirtableId', headerName: 'Airtable ID', width: 150 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'roles.admin', headerName: 'Admin', type: 'boolean', editable: true, valueGetter: (params) => params.row.roles ? params.row.roles.admin : null },
        { field: 'roles.manager', headerName: 'Manager', type: 'boolean', editable: true, valueGetter: (params) => params.row.roles ? params.row.roles.manager : null },
        { field: 'roles.brandTeam', headerName: 'Brand Team', type: 'boolean', editable: true, valueGetter: (params) => params.row.roles ? params.row.roles.brandTeam : null },
        { field: 'roles.salesTeam', headerName: 'Sales Team', type: 'boolean', editable: true, valueGetter: (params) => params.row.roles ? params.row.roles.salesTeam : null },
        { field: 'roles.externalManager', headerName: 'External Manager', type: 'boolean', editable: true, valueGetter: (params) => params.row.roles ? params.row.roles.externalManager : null },
        // {
        //     field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: GridRowParams) => [
        //         <GridActionsCellItem label="Test 1" /*icon={<CheckBoxOutlined />}*/ onClick={() => console.log("YO")} showInMenu />,
        //         <GridActionsCellItem label="Test 2" /*icon={<CheckBoxOutlined />}*/ onClick={() => console.log("YO")} showInMenu />
        //     ]
        // }
    ];

    return (
        <>
            <Layout>
                <div className="my-300 container-fluid" >
                    <h1 className="mb-25">Managers</h1>
                    <div className="mb-150 font-style-italic">Work In Progress Feature</div>
                    <DataGridPremium
                        apiRef={apiRef}
                        throttleRowsMs={200}
                        showCellVerticalBorder
                        columns={columns}
                        rows={managers}
                        autoHeight
                        hideFooter
                        // cellModesModel={}   // https://github.com/mui/mui-x/issues/2186
                        components={{
                            BooleanCellTrueIcon: CheckBoxOutlined,
                            BooleanCellFalseIcon: CheckBoxOutlineBlankOutlined,
                        }}
                        // onCellEditCommit={(params: GridCellEditCommitParams, event: MuiEvent) => {
                        //     if (params.field.includes('roles')) {
                        //         const role = params.field.replace('roles.', '');
                        //         updateUserRole(params.id.toString(), role, params.value);
                        //     }
                        // }}
                        processRowUpdate={(newRow, oldRow) => {
                            const changedFields = grailUtil.findChangedObjectKeys(oldRow, newRow);
                            if (Object.keys(changedFields).length === 1) {
                                const field = Object.keys(changedFields)[0];
                                const value = newRow[field];
                                const docId = newRow.id.toString();

                                if (field.includes('roles')) {
                                    const role = field.replace('roles.', '');
                                    updateUserRole(docId.toString(), role, value);
                                }
                            }
                            return newRow;
                        }}
                    />
                </div>
            </Layout>
        </>
    );
}