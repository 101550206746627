import React, { useContext } from "react";
import { UserContext } from "../services/firebase";
import { addNewUserInfo } from "../services/firebase";
import LayoutOnboarding from "../components/LayoutOnboarding";
import { User } from "../shared/types";
// import { useNavigate } from "react-router-dom";

const NewUserInfo: React.FC<any> = () => {
    const user = useContext(UserContext);
    // const navigate = useNavigate();

    async function submitForm(e: any, user: any) {
        e.preventDefault();

        if (e?.target?.campaignType.value) {
            const newUserInfo: User = {
                firstName: e.target.firstName.value,
                lastName: e.target.lastName.value,
                company: e.target.company.value,
                campaignType: e.target.campaignType.value,
            };

            if (!user?.email?.toLowerCase().includes('grail-talent.com') && !user?.orgDomain?.toLowerCase().includes('grail-talent.com')) {
                newUserInfo.roles = {
                    booker: true,
                }
            }

            await addNewUserInfo(user.id, newUserInfo);
            // navigate('/');
        }
    }

    return (
        <div className="NewUserInfo">
            <LayoutOnboarding>
                <form className="card" onSubmit={(e) => submitForm(e, user)}>
                    <h2 className="mb-100">Almost Done!</h2>
                    <div className="mb-100 font-14">Just one more step!</div>

                    <div className="form-group mb-100">
                        <label className="required">First Name</label>
                        <input name="firstName" placeholder="First Name" required defaultValue={user?.firstName || ""} />
                    </div>

                    <div className="form-group mb-100">
                        <label className="required">Last Name</label>
                        <input name="lastName" placeholder="Last Name" required defaultValue={user?.lastName || ""} />
                    </div>

                    <div className="form-group mb-100">
                        <label className="required">Company</label>
                        <input name="company" placeholder="Company" required defaultValue={user?.company || ""} />
                    </div>

                    <div className="form-group">
                        <label className="required">Your Promo Type</label>
                        <div className="hint">What type of promotions do you primarily run?</div>
                        {/* defaultValue="" */}
                        {/* value={user?.campaignType || ""} */}
                        <select className="mb-100" name="campaignType" required defaultValue={user?.campaignType || ""}>
                            <option value="" disabled>Choose Type...</option>
                            <option value="Song">Song Promos</option>
                            <option value="Brand">Brand Promos</option>
                            <option value="Multi">Both (I work at an Agency)</option>
                        </select>
                    </div>

                    <input className="btn btn-primary btn-block" type="submit" value="Let's Do This!" />
                </form>
                {/* {user?.campaignType ? <Redirect to="/" /> : null} */}
            </LayoutOnboarding>
        </div>
    );
}

export default NewUserInfo;