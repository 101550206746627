// EditTalent
import { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';
import { getTalentById, updateTalent, UserContext, useFreshManagers } from "../services/firebase";
import { Prices, Talent } from "../shared/types";
import { useForm, FieldValues, Controller } from "react-hook-form";
import grailUtil from "../services/grailUtil";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";

// Components
import LayoutOnboarding from "../components/LayoutOnboarding";
import CountrySelect from "../components/CountrySelect";
import Alert from "@mui/material/Alert";
import ManagerSelect from "../components/ManagerSelect";


export default function EditTalent() {
    const user = useContext(UserContext);
    const [talent, setTalent] = useState<Talent>();
    const { talentId } = useParams<{ talentId: string }>();
    const [showAlert, setShowAlert] = useState(<></>);
    const alertRef = useRef<any>();

    const managersQuery = useFreshManagers();
    const managers = managersQuery.flatData;

    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm();

    async function submitForm(formData: FieldValues) {
        // console.log(`formData`, formData);

        let country = null;
        if (formData.country) {
            if (typeof formData.country === 'string') {
                country = formData.country
            } else if (formData.country.code) {
                country = formData.country.label;
            }
        }

        let prices: Prices = {};
        Object.keys(formData.prices).forEach(function (key) {
            prices[key as keyof Prices] = parseInt(formData.prices[key]) || null;
        });

        const talentData = {
            ...formData,
            ...(formData.birthday && { birthday: dayjs(formData.birthday).toDate() }),
            id: talentId,
            country: country,
            lastUpdate: "EDIT_TALENT_FORM",
            managers: formData.managerIds.map((managerId: string) => {
                const managerDoc = managers.find((manager) => manager.id === managerId);
                return {
                    id: managerId,
                    airtableId: managerDoc?.managerAirtableId || "",
                    firstName: managerDoc?.firstName || "",
                    lastName: managerDoc?.lastName || "",
                    email: managerDoc?.email || "",
                    phone: managerDoc?.phone || "",
                };
            }),
            prices: prices,
        };

        console.log(talentData);
        const res = await updateTalent(talentData);

        if (res.success) {
            setShowAlert(<Alert ref={alertRef} className="mb-100" onClose={() => setShowAlert(<></>)} severity="success">Your changes have been saved!</Alert>);
        } else {
            console.log(res.error);
            console.log(`Failed Saving:`, JSON.stringify(talentData));

            setShowAlert(
                <Alert ref={alertRef} className="mb-100" onClose={() => setShowAlert(<></>)} severity="error">
                    There was an issue saving your changes... {JSON.stringify(res.error)}
                    <div className="mt-100 font-10">{res.error.toString()}</div>
                </Alert >);
            Sentry.captureException(res.error);
        }
        if (alertRef?.current) {
            alertRef.current.scrollIntoView();
        }
    }

    useEffect(() => {
        if (!talent) {
            getInitialProps();
        }

        async function getInitialProps() {
            if (talentId) {
                const newTalent = await getTalentById(talentId);
                setTalent(newTalent);
            }
        }
    }, [talentId, talent]);

    return (
        <div className="EditTalent">
            <LayoutOnboarding>
                {showAlert}

                {talentId && talent ? (
                    <>
                        <form className="card text-left" onSubmit={handleSubmit(submitForm)} >
                            <div className="mb-100">
                                <strong>Airtable ID:</strong> {talent.id}
                            </div>

                            <div className={`form-group mb-100 ${errors.managerIds ? 'error' : ''}`}>
                                <label>Manager(s)</label>
                                <Controller
                                    control={control}
                                    name="managerIds"
                                    defaultValue={talent?.managerIds || []}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <ManagerSelect managers={managers} value={value} disabled={!user?.roles?.admin} onChange={(e: any, managerIds: string[]) => {
                                            onChange(managerIds);
                                            // set the hidden field for the airtableManagerIds
                                            setValue('airtableManagerIds', managerIds.map((id) => managers.find((manager) => manager.id === id)?.managerAirtableId));
                                        }} />
                                    )}
                                />
                            </div>

                            <input type="hidden" {...register("airtableManagerIds", {
                                value: talent?.airtableManagerIds,
                            })} />

                            <div className={`form-group mb-100 ${errors.tiktokUser ? 'error' : ''}`}>
                                <label>TikTok User</label>
                                <input type="text" {...register("tiktokUser", {
                                    value: talent?.tiktokUser,
                                    pattern: { value: /^[a-zA-Z0-9-_.]*$/, message: "Do not include @ or special characters" }
                                })} />
                                {errors.tiktokUser && <div className="error-msg">{errors.tiktokUser.message as string}</div>}
                                {/* {console.log(`errors`, errors)} */}
                            </div>

                            <div className={`form-group mb-100 ${errors.instagramUser ? 'error' : ''}`}>
                                <label>Instagram User</label>
                                <input type="text" {...register("instagramUser", {
                                    value: talent?.instagramUser,
                                    pattern: { value: /^[a-zA-Z0-9-_.]*$/, message: "Do not include @ or special characters" }
                                })} />
                                {errors.instagramUser && <div className="error-msg">{errors.instagramUser.message as string}</div>}
                            </div>

                            <div className={`form-group mb-100 ${errors.igFollowers ? 'error' : ''}`}>
                                <label>Instagram Followers</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("igFollowers", {
                                    value: talent?.igFollowers,
                                    pattern: { value: /^[0-9]*$/, message: "Enter a valid integrer without special characters" }
                                })} />
                                {errors.igFollowers && <div className="error-msg">{errors.igFollowers.message as string}</div>}
                            </div>

                            <div className={`form-group mb-100 ${errors.youtubeUser ? 'error' : ''}`}>
                                <label>YouTube Channel Handle</label>
                                <input type="text" {...register("youtubeUser", {
                                    value: talent?.youtubeUser,
                                    pattern: {
                                        value: /^[a-zA-Z0-9-_.]*$/,
                                        message: "Do not include @ or special characters"
                                    }
                                })} />
                                {errors.youtubeUser && <div className="error-msg">{errors.youtubeUser.message as string}</div>}
                            </div>

                            <div className={`form-group mb-100 ${errors.igFollowers ? 'error' : ''}`}>
                                <label>YouTube Subscribers</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("ytFollowers", {
                                    value: talent?.ytFollowers,
                                    pattern: { value: /^[0-9]*$/, message: "Enter a valid integrer without special characters" }
                                })} />
                                {errors.ytFollowers && <div className="error-msg">{errors.ytFollowers.message as string}</div>}
                            </div>

                            <div className="form-group mb-100">
                                <label>Image</label>
                                <span>Ability to add images coming soon...</span>
                                {/* imgUrl */}
                            </div>

                            <hr />

                            <h2>TikTok Prices</h2>

                            <div className="form-group mb-100 mt-100">
                                <label>TikTok Brand Price</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceTikTok", { value: talent?.prices?.brandPriceTikTok })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>TikTok Song Price</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.songPriceTikTok", { value: talent?.prices?.songPriceTikTok })} />
                            </div>

                            <hr />

                            <h2>Instagram Prices</h2>
                            <h3 className="simple-header my-75">Brand Prices</h3>

                            <div className="form-group mb-100">
                                <div className="flex align-items-center">
                                    <label>Reel</label>
                                    <div className="font-12 mb-25">&nbsp;-&nbsp;(Default Brand Price)</div>
                                </div>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceInstagramReel", { value: talent?.prices?.brandPriceInstagramReel })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Grid Post</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceInstagramPost", { value: talent?.prices?.brandPriceInstagramPost })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>3-Frame Story</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceInstagramStory", { value: talent?.prices?.brandPriceInstagramStory })} />
                            </div>

                            <h3 className="simple-header mt-150 mb-75">Song Price</h3>

                            <div className="form-group mb-100">
                                <label>IG Song Price</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.songPriceInstagram", { value: talent?.prices?.songPriceInstagram })} />
                            </div>

                            <hr />

                            <h2>YouTube Prices</h2>
                            <h3 className="simple-header my-75">Brand Prices</h3>

                            <div className="form-group mb-100">
                                <div className="flex align-items-center">
                                    <label>60-90s Integration</label>
                                    <div className="font-12 mb-25">&nbsp;-&nbsp;(Default Brand Price)</div>
                                </div>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceYouTubeIntegration", { value: talent?.prices?.brandPriceYouTubeIntegration })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Pre-Roll</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceYouTubePreroll", { value: talent?.prices?.brandPriceYouTubePreroll })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>YouTube Short</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceYouTubeShort", { value: talent?.prices?.brandPriceYouTubeShort })} />
                            </div>


                            {/* <div className="form-group mb-100">
                                <label>Video Price</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.brandPriceYouTubeVideo", { value: talent?.prices?.brandPriceYouTubeVideo })} />
                            </div> */}

                            <h3 className="simple-header mt-150 mb-75">Song Price</h3>
                            <div className="form-group mb-100">
                                <label>YT Song Price</label>
                                <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} {...register("prices.songPriceYouTube", { value: talent?.prices?.songPriceYouTube })} />
                            </div>

                            <hr />

                            <div className="form-group mb-100">
                                <label>Hide on Booking Platform</label>
                                <div className="flex">
                                    <input type="checkbox" {...register("hideUser", { value: talent.hideUser })} />
                                    <span className="text-left ml-50 font-14">If checked, creator will be hidden from <em>clients</em> but not from managers.</span>
                                </div>
                            </div>

                            <div className="form-group mb-100">
                                <label>Delete from Booking Platform</label>
                                <div className="flex">
                                    <input type="checkbox" {...register("deleted", { value: talent.deleted })} />
                                    <span className="text-left ml-50 font-14">If checked, creator will be hidden from both clients <em> and managers</em>. USE CAREFULLY.</span>
                                </div>
                            </div>

                            <div className="form-group mb-100">
                                <label>Show on Marketing Site?</label>
                                <div className="flex">
                                    <input type="checkbox" {...register("showOnWebsite", { value: talent?.showOnWebsite })} />
                                    <span className="text-left ml-50 font-14">Show on <a href="https://grail-talent.com/talent" target="_blank" rel="noreferrer">https://grail-talent.com/talent</a></span>
                                </div>
                            </div>

                            <hr />

                            <div className="form-group mb-100">
                                <label>Name</label>
                                <input type="text" {...register("name", { value: talent.name })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Country</label>
                                <Controller
                                    control={control}
                                    name="country"
                                    defaultValue={talent?.country || ''}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <CountrySelect onChange={onChange} value={value} required={true} />
                                    )}
                                />
                            </div>

                            <div className="form-group mb-100">
                                <label>Birthday</label>
                                <input type="date" {...register("birthday", { value: talent.birthday ? grailUtil.timestampOrDateToDate(talent.birthday) : null })} />
                            </div>

                            <div className="form-group mb-100">
                                <label>Creator's Currency</label>
                                <select {...register("currency", { value: talent.currency })}>
                                    <option value="" disabled>Select a Currency</option>
                                    <option value="USD">USD</option>
                                    <option value="GBP">GBP</option>
                                </select>
                            </div>

                            <hr />

                            {/* <div className="form-group mb-100 text-left">
                                <label>Manager(s)</label>
                                {talent.managerIds ? talent.managerIds.map((managerId) => (
                                    <div key={managerId}>
                                        {managerId}
                                    </div>
                                )) : null}
                            </div> */}

                            {/* <div className="form-group mb-100">
                                <label>Tags</label>
                            </div>


                            <div className="form-group mb-100">
                                <label>Lists</label>
                            </div> */}

                            <input className="btn btn-red" type="submit" value="Save Creator" />
                        </form>
                    </>
                ) : null}
            </LayoutOnboarding>
        </div>
    );
}