import { useState } from "react";
import { User, Client, CampaignType, AutocompleteOption } from "../shared/types";

// Components
import ClientSelect from './ClientSelect';

// MUI Compontents
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

interface BookerSelectProps {
    onBookerSelect: (user: User | undefined) => unknown,
    value: User | undefined,
    bookers: User[],
    clients: Client[],
    disabled?: boolean,
}

interface DialogValue {
    firstName: string,
    lastName: string,
    email: string,
    campaignType: string,
    client: Client | undefined,
}

const bookerFilter = createFilterOptions<AutocompleteOption<User>>();

const initialDialogState = {
    firstName: '',
    lastName: '',
    email: '',
    campaignType: '',
    client: undefined
};

function BookerSelect(props: BookerSelectProps) {
    const { bookers, disabled } = props;
    const bookerOptions: AutocompleteOption<User>[] = bookers.filter((booker) => !booker.hideBooker)?.map((booker) => {
        return {
            val: booker,
            label: generateBookerLabel(booker),
        }
    });

    const clients = props.clients;
    const clientOptions: AutocompleteOption<Client>[] = clients.map((client) => {
        return {
            val: client,
            label: client.name + ' *** ' + client.airtableId + ' *** ' + client.id
        }
    });

    const [dialogValue, setDialogValue] = useState<DialogValue>(initialDialogState);
    const [dialogOpen, toggleDialogOpen] = useState(false);
    const [dialogError, setDialogError] = useState<string>();

    // BOOKER
    function onBookerChange(newValue: AutocompleteOption<User> | null | string) {
        if (typeof newValue === 'string') {
            // Never happens... see: https://github.com/mui/material-ui/issues/33093
        } else if (newValue && newValue.inputValue) {
            toggleDialogOpen(true);
        } else if (newValue === null) {
            props.onBookerSelect(undefined);
        }
        else {
            props.onBookerSelect(newValue.val);
        }
    }

    function generateBookerLabel(user: User) {
        return `${user.email}: ${user.firstName || ""} ${user.lastName || ""} -- ${user.company || ""} -- ${user.id || 'New Booker'}`;
    }

    const handleBookerDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const allBookerEmails = bookers.map((booker) => booker.email?.toLowerCase().trim());

        if (allBookerEmails.includes(dialogValue.email.toLowerCase().trim())) {
            // alert("DUPLICATE!");
            setDialogError('A booker with this email already exists. Please select existing booker.');
        } else if (!dialogValue.client?.name) {
            setDialogError('Please select a client.');
        } else {
            const newBooker = {
                ...dialogValue,
                campaignType: dialogValue.campaignType as CampaignType,
                company: dialogValue.client!.name,
                ...(dialogValue.client?.id && { clientId: dialogValue.client.id }),
                ...(dialogValue.client?.airtableId && { airtableId: dialogValue.client.airtableId }),
            }
            delete newBooker.client;

            props.onBookerSelect(newBooker);
            toggleDialogOpen(false);
            setDialogError('');
            setDialogValue(initialDialogState);
        }
    };

    // CLIENT
    function onClientChange(newValue: string | AutocompleteOption<Client> | null) {
        if (typeof newValue === 'string') {
            // Never happens... see: https://github.com/mui/material-ui/issues/33093
            // console.log("IT'S A STRING!");
        } else if (newValue && newValue.inputValue) {
            setDialogValue({
                ...dialogValue,
                client: {
                    name: newValue.inputValue,
                }
            });
        } else if (newValue === null) {
            setDialogValue({
                ...dialogValue,
                client: undefined,
            })
        } else {
            setDialogValue({
                ...dialogValue,
                client: newValue?.val
            })
        }
    }

    // function ClientSelect(props: any) {
    //     return (
    //         <Autocomplete
    //             id="client-select"
    //             value={props.value ? { val: props.value, label: props.value.name } : null}
    //             // isOptionEqualToValue={(option, value) => option.val === value.val}
    //             freeSolo
    //             options={clientOptions}
    //             autoHighlight
    //             getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
    //             onChange={props.onChange}
    //             renderOption={(props, option) => (
    //                 <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
    //                     {option.label}
    //                 </Box>

    //             )}
    //             renderInput={(params) => (
    //                 <TextField
    //                     {...params}
    //                     label="Select a Client"
    //                     inputProps={{
    //                         ...params.inputProps,
    //                         autoComplete: 'new-password', // disable autocomplete and autofill
    //                     }}
    //                     required
    //                 />
    //             )}

    //             filterOptions={(options, params) => {
    //                 const filtered = clientFilter(options, params);
    //                 const { inputValue } = params;

    //                 // Suggest the creation of a new value
    //                 const isExisting = options.some((option) => inputValue === option.label);
    //                 if (inputValue !== '' && !isExisting) {
    //                     filtered.push({
    //                         val: undefined,
    //                         inputValue: params.inputValue,
    //                         label: `Add New Client: "${inputValue}"`,
    //                     });
    //                 }

    //                 return filtered;
    //             }}
    //         />
    //     );
    // }

    return (
        <>
            {bookerOptions ? (
                <>
                    <Autocomplete
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '0.375rem 0.75rem',
                                height: 'calc(1.5em + 0.75rem + 2px)',
                                '& .MuiAutocomplete-input': {
                                    padding: 'unset',
                                },
                                backgroundColor: '#fff'
                            },
                            '& .MuiFormControl-root': {
                                margin: 0,
                            },
                            '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                                // border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                                border: '1px solid #ced4da !important',
                            },
                            '&.Mui-focused': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    //     border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
                                    border: '1px solid #ced4da !important',
                                }
                            },
                            '& .MuiOutlinedInput-input': {
                                border: 'none !important'
                            }
                        }}
                        id="booker-select"
                        freeSolo
                        value={props.value ? { val: props.value, label: generateBookerLabel(props.value) } : null}
                        options={bookerOptions}
                        autoHighlight
                        getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                        onChange={(event, newVal) => onBookerChange(newVal)}
                        clearOnBlur
                        disabled={disabled}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.label}
                            </Box>

                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // label="Select a Booker"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}

                        filterOptions={(options, params) => {
                            const filtered = bookerFilter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);

                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    val: undefined,
                                    inputValue: params.inputValue,
                                    label: `Add New Booker "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                    />

                    <Dialog open={dialogOpen} onClose={() => toggleDialogOpen(false)}>
                        <form onSubmit={handleBookerDialogSubmit}>
                            <DialogTitle>Add a new booker</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    New Booker? Add their details below!
                                </DialogContentText>
                                <div className="form-group mt-100 mb-100">
                                    <label className="required">First Name</label>
                                    <input
                                        id="firstName"
                                        value={dialogValue?.firstName}
                                        onChange={(event) =>
                                            setDialogValue({
                                                ...dialogValue,
                                                firstName: event.target.value,
                                            })
                                        }
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-100">
                                    <label className="required">Last Name</label>
                                    <input
                                        id="lastName"
                                        value={dialogValue.lastName}
                                        onChange={(event) =>
                                            setDialogValue({
                                                ...dialogValue,
                                                lastName: event.target.value,
                                            })
                                        }
                                        type="text"
                                        required
                                    />
                                </div>

                                <div className="form-group mb-100">
                                    <label className="required">Email</label>
                                    <input
                                        id="email"
                                        value={dialogValue.email}
                                        onChange={(event) =>
                                            setDialogValue({
                                                ...dialogValue,
                                                email: event.target.value,
                                            })
                                        }
                                        type="email"
                                        required
                                    />
                                </div>

                                <div className="form-group mb-100">
                                    <label className="required">Campaign Type</label>
                                    <select className="mb-100" name="campaignType" required defaultValue=""
                                        onChange={(event) => setDialogValue({ ...dialogValue, campaignType: event.target.value })}>
                                        <option value="" disabled>Choose Type...</option>
                                        <option value="Song">Song Promos</option>
                                        <option value="Brand">Brand Promos</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label className="required">Client</label>
                                    {/* <ClientSelect value={dialogValue.client} onChange={(event: any, value: AutocompleteOption<Client>) => {
                                        onClientChange(value);
                                    }} /> */}
                                    <ClientSelect clientOptions={clientOptions} value={dialogValue.client} onChange={(event: any, value: string | AutocompleteOption<Client> | null) => {
                                        onClientChange(value);
                                    }} />
                                </div>

                                {dialogError ? <div className="form-group error"><span className="error-msg">{dialogError}</span></div> : null}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { toggleDialogOpen(false); setDialogValue(initialDialogState); setDialogError(''); }}>Cancel</Button>
                                <Button type="submit">Add</Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </>
            ) : null}

        </>
    );
}

export default BookerSelect;