import { useState, useEffect, useContext } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import CampaignSelect from './CampaignSelect';
import { Campaign } from '../shared/types';
import { NewBookingState } from "../pages/NewBooking";
import { UserContext } from "../services/firebase";

interface BookingFormProps {
    bookingState: NewBookingState,
    reactHookForm: UseFormReturn,
}

function BookingForm(props: BookingFormProps) {
    const { reactHookForm } = props;
    const { booker, filter, campaigns } = props.bookingState;
    const authenticatedUser = useContext(UserContext);
    const isManager = authenticatedUser?.roles?.manager;
    const isBrandTeam = authenticatedUser?.roles?.brandTeam;

    // 
    const { control, register, setValue, formState: { errors } } = reactHookForm;
    const [fileUrl, setFileUrl] = useState<string>();

    const [editUserDetails, setEditUserDetails] = useState(false);
    const [selectedCampaignId, setSelectedCampaignId] = useState<string>();
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();

    // TJN TO-DO MAYBE... remove effect?
    useEffect(() => {
        console.log("BOOKING FORM EFFECT");
        const campaign = campaigns.find((campaign: Campaign) => campaign.id === selectedCampaignId);

        if (campaign?.id) {
            setValue('soundUrl', campaign?.soundUrl || "", { shouldValidate: true });
            setValue('details', campaign?.details || "", { shouldValidate: true });
            if (campaign.fileUrl) {
                setFileUrl(campaign?.fileUrl);
            }
            setSelectedCampaign(campaign);
        }
    }, [selectedCampaignId, campaigns, setValue])

    return (
        <section className="BookingForm">
            {!editUserDetails && booker?.firstName && booker?.lastName && booker?.company && booker?.email ?
                <>
                    <div className="flex justify-content-between mb-25">
                        <strong className="font-20">Your Details</strong>
                        {booker && (!booker.firstName || !booker.lastName || !booker.company || !booker.email) ? <span className="BookingFormChange" onClick={(e) => setEditUserDetails(true)}>Edit</span> : null}
                    </div>
                    <div className="UserDetailsSummary mb-200">
                        <div className="flex justify-content-between mb-25">
                            <strong>Name</strong>
                            <div>{booker?.firstName} {booker?.lastName}</div>
                        </div>
                        <div className="flex justify-content-between mb-25">
                            <strong>Email</strong>
                            <div>{booker?.email}</div>
                        </div>
                        <div className="flex justify-content-between mb-25">
                            <strong>Company</strong>
                            <div>{booker?.company}</div>
                        </div>
                        <div className="flex justify-content-between">
                            <strong>Phone</strong>
                            <div>{booker?.phone || '–'}</div>
                        </div>
                    </div>
                    <input type="hidden" value={booker?.firstName} {...register("firstName", { required: true })} />
                    <input type="hidden" value={booker?.lastName} {...register("lastName", { required: true })} />
                    <input type="hidden" value={booker?.email} {...register("email", { required: true })} />
                    <input type="hidden" value={booker?.company} {...register("company", { required: true })} />
                    <input type="hidden" value={booker?.phone || ""} {...register("phone", { required: false })} />
                </>
                :
                <>
                    <div className="flex flex-wrap">
                        <div className={`flex-1-0-auto mr-50 form-group mb-100 ${errors.firstName ? 'error' : ''}`}>
                            <label className="required">First Name</label>
                            <input placeholder="First Name" defaultValue={booker?.firstName} {...register("firstName", { required: true })} />
                            {errors.firstName && <div className="error-msg">First name is required.</div>}
                        </div>

                        <div className={`flex-1-0-auto mr-50 form-group mb-100 ${errors.lastName ? 'error' : ''}`}>
                            <label className="required">Last Name</label>
                            <input placeholder="Last Name" defaultValue={booker?.lastName} {...register("lastName", { required: true })} />
                            {errors.lastName && <div className="error-msg">Last name is required.</div>}
                        </div>
                    </div>

                    <div className={`form-group mb-100 ${errors.email ? 'error' : ''}`}>
                        <label className="required">Email</label>
                        <input type="email" placeholder="Email" defaultValue={booker?.email} {...register("email", { required: true, pattern: { value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, message: "Enter a valid email." } })} />
                        {errors.email && <div className="error-msg">Valid Email is required.</div>}
                    </div>

                    <div className={`form-group mb-100 ${errors.company ? 'error' : ''}`}>
                        <label className="required">Company</label>
                        <input placeholder="Company" defaultValue={booker?.company} {...register("company", { required: true })} />
                        {errors.company && <div className="error-msg">Company is required.</div>}
                    </div>

                    <div className={`form-group mb-100 ${errors.phone ? 'error' : ''}`}>
                        <label>Phone Number</label>
                        <input placeholder="Phone" defaultValue={booker?.phone} {...register("phone", { required: false })} />
                        {errors.phone && <div className="error-msg">Phone Number is required.</div>}
                    </div>
                </>
            }

            <div className={`form-group mb-100 ${errors.campaign ? 'error' : ''}`}>
                <label className="required">Campaign Name</label>
                {/* <input placeholder="Old Town Road - Lil Nas X + Billy Ray Cyrus" {...register("campaignName", { required: true })} /> */}

                <Controller
                    name="campaign"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                        <CampaignSelect
                            isErrored={Boolean(errors.campaign)}
                            value={value || null}
                            onChange={(newVal) => { onChange(newVal); setSelectedCampaignId(newVal?.id || undefined) }}
                            placeholder="Old Town Road - Lil Nas X + Billy Ray Cyrus"
                            options={campaigns?.map((campaign: Campaign) => { return { id: campaign.id, label: campaign.campaignName || "Unnamed Campaign" } })} />
                    )}
                />
                {errors.campaign && <div className="error-msg">Campaign Name is required.</div>}
            </div>

            <div className="form-group mb-100">
                <label>Platform</label>
                <div className="flex align-items-center">
                    {filter.campaignPlatform === "TikTok" ? (<><img className="true-color-icon mr-75" src="/icon-tiktok.svg" alt="TikTok" /> TikTok</>) : null}
                    {filter.campaignPlatform === "Instagram" ? (<><img className="true-color-icon mr-75" src="/icon-instagram.svg" alt="Instagram" /> Instagram</>) : null}
                    {filter.campaignPlatform === "YouTube" ? (<><img className="true-color-icon mr-75" src="/icon-youtube.svg" alt="YouTube" /> YouTube</>) : null}
                </div>
            </div>

            {filter.showSongPrice ?
                <div className={`form-group mb-100 ${errors.soundUrl ? 'error' : ''}`}>
                    <label className="required">Primary Sound URL</label>
                    <input
                        type="text"
                        // readOnly={Boolean(selectedCampaignId)}
                        readOnly={Boolean(selectedCampaignId && selectedCampaign?.soundUrl)}
                        placeholder="https://www.tiktok.com/music/Old-Town-Road-6683330941219244813"
                        {...register("soundUrl", {
                            required: true,
                            setValueAs: (val) => {
                                try {
                                    const url = decodeURI(val);
                                    return url;
                                } catch (e) {
                                    console.log(`error trying decodeURI`, val, e);
                                    return val;
                                }
                            },
                            pattern: { value: /^(https:\/\/www\.tiktok\.com\/music\/[A-Za-zÀ-ÖØ-öø-ÿ0-9-']*[0-9]|https:\/\/vm\.tiktok\.com\/[A-Za-zÀ-ÖØ-öø-ÿ0-9-]*|https:\/\/www\.instagram\.com\/reels\/audio\/[A-Za-zÀ-ÖØ-öø-ÿ0-9-]*|https:\/\/www\.youtube\.com\/source\/*|https:\/\/youtube\.com\/source\/*)/, message: "Valid URLs start with https://www.tiktok.com/music/ OR https://vm.tiktok.com/ OR https://www.youtube.com/source/" },
                            // pattern: selectedCampaignId ? { value: /./, message: 'Oops' } : { value: /^(https:\/\/www\.tiktok\.com\/music\/[A-Za-z0-9-']*[0-9]|https:\/\/vm\.tiktok\.com\/[A-Za-z0-9-]*)/, message: "Valid URLs start with https://www.tiktok.com/music/ OR https://vm.tiktok.com/" }
                        })}
                    />
                    {errors.soundUrl && <div className="error-msg">{errors.soundUrl.message ? (<span>Use: &nbsp;&nbsp;<strong>https://www.tiktok.com/music/...</strong>&nbsp;&nbsp;or&nbsp;&nbsp; <strong>https://vm.tiktok.com/...</strong>&nbsp;&nbsp;or&nbsp;&nbsp;<strong>https://www.instagram.com/reels/audio/...</strong>&nbsp;&nbsp;or&nbsp;&nbsp;<strong>https://www.youtube.com/source/...</strong></span>) : "Primary Sound URL is required."}</div>}
                </div>
                : null}

            {/* {isManager && !isBrandTeam ? null : ( */}
            <>
                <div className={`form-group mb-100 ${errors.details ? 'error' : ''}`}>
                    <label className={isManager ? "" : "required"}>Campaign Details, Deliverables, &amp; Timeline</label>
                    {filter.campaignPlatform !== "TikTok" && !filter.showSongPrice ? (<small className="display-block text-italic font-red mb-25"><strong className="text-uppercase">Important:</strong> Be sure to specify post types!</small>) : null}
                    <textarea
                        placeholder="What type of content are you looking for?"
                        {...register("details", {
                            required: isManager ? false : true,
                        })}
                    />
                    {errors.details && <div className="error-msg">Campaign Details are required.</div>}
                </div>

                {fileUrl ? (
                    <div className="flex align-items-center">
                        <a className="alternate" href={fileUrl} target="_blank" rel="noreferrer">Existing File</a>
                        <button className="btn btn-blue ml-100" onClick={() => setFileUrl(undefined)}>
                            <DeleteForeverIcon />
                            <span className="ml-50">Add New File</span>
                        </button>
                    </div>
                ) : (
                    <div className={`form-group mb-100 ${errors.file ? 'error' : ''}`}>
                        <input type="file" accept="image/*,.pdf,.doc,.docx" {...register("file", { required: false })} />
                    </div>
                )}
            </>
            {/* )} */}

            {isBrandTeam ? (
                <>
                    <div>
                        <div className={`form-group ${errors.sendCampaignReceipt ? 'error' : ''}`}>
                            <label className="required">Email Receipt to booker ({booker?.email})?</label>
                        </div>
                        <div className="">
                            <input {...register("sendCampaignReceipt", { required: true })} type="radio" value="true" id="sendCampaignReceiptYes" />
                            <label htmlFor="sendCampaignReceiptYes">Yes</label>
                        </div>
                        <div>
                            <input {...register("sendCampaignReceipt", { required: true })} type="radio" value="false" id="sendCampaignReceiptNo" />
                            <label htmlFor="sendCampaignReceiptNo">No</label>
                        </div>
                        <div className={`form-group mb-100 ${errors.sendCampaignReceipt ? 'error' : ''}`}>
                            {errors.sendCampaignReceipt && <div className="error-msg">Choose Yes or No.</div>}
                        </div>
                    </div>

                    <div>
                        <div className={`form-group ${errors.sendBookingOpp ? 'error' : ''}`}>
                            <label className="required">Email Opportunity to Managers?</label>
                        </div>
                        <div className="">
                            <input {...register("sendBookingOpp", { required: true })} type="radio" value="true" id="sendBookingOppYes" />
                            <label htmlFor="sendBookingOppYes">Yes</label>
                        </div>
                        <div>
                            <input {...register("sendBookingOpp", { required: true })} type="radio" value="false" id="sendBookingOppNo" />
                            <label htmlFor="sendBookingOppNo">No</label>
                        </div>
                        <div className={`form-group ${errors.sendBookingOpp ? 'error' : ''}`}>
                            {errors.sendBookingOpp && <div className="error-msg">Choose Yes or No.</div>}
                        </div>
                    </div>
                </>
            ) : null}

            {true ? <>
                <div className="form-group mt-100 mb-100">
                    <label className="required">Are you interested in Boosting posts for this campaign?</label>
                    <div className="flex">
                        <input type="checkbox" {...register("boostInterest")} /> <span>Yes, please reach out to me about Boosting</span>
                    </div>
                </div>
            </> : null}

            {!isManager && !booker?.clientWideSettings?.paymentTerms?.termsAccepted ? <>
                <div className="form-group mt-100 mb-100">
                    <label className="required">Do you agree to the&nbsp;<a href="https://grail-talent.com/payment-terms" target="_blank" rel="noreferrer">Grail Talent Payment terms</a>?</label>
                    <div className="flex">
                        <input type="checkbox" {...register("paymentTerms.termsAccepted", { required: true, })} required /> <span>Yes, I agree</span>
                    </div>
                </div>
            </> : null}
        </section>
    )
}

export default BookingForm;