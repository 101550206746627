import './BookingNavigation.scss';

function BookingNavigation(props: any) {
    const { bookingStep, navigateToStep, showMobileSummary, setShowMobileSummary, selectedTalent, booker, formSubmitDisabled } = props;
    const stepCount = 2;
    const currentStep = bookingStep === 'list' ? 1 : 2;

    return (
        <div className="BookingNavigation">
            <div className="container">
                <div className="label mb-50 hidden-lg-down">Step {currentStep} of {stepCount}</div>
                <div onClick={(e) => setShowMobileSummary(!showMobileSummary)} className="hidden-lg-up btn btn-text-red mb-50">{showMobileSummary ? 'Hide' : 'View'} Summary</div>
            </div>

            <div className="container">
                {bookingStep === 'form' ? (
                    <button type="button" className="btn btn-blue mr-100" onClick={(e) => navigateToStep('list')}>
                        &laquo; Back
                    </button>
                ) : null}

                {bookingStep === 'list' ? (
                    <button
                        type="button" onClick={(e) => navigateToStep('form')} className="btn btn-red"
                        disabled={!booker || Object.keys(selectedTalent).length < 1}>
                        Next
                    </button>
                ) : null}

                {bookingStep === 'form' ? (
                    <input disabled={formSubmitDisabled} className="btn btn-red" type="submit" value="Submit Brief" />
                ) : null}
            </div>

        </div>
    );
}

export default BookingNavigation;